import React from 'react';
import { withTranslation } from 'react-i18next';
import { history } from '../../routes';
import config from '../../config';

import LayoutSmall from '../../components/layout/LayoutSmall';

import Icon from '../../libraries/icons';
import { Link } from 'react-router-dom';
import LayoutResponsiveWSmall from '../../components/layout/LayoutResponsiveWSmall';



class InfoAssistance extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
    }
  }

  componentDidMount() {
    console.log('ASSITANCE PAGE');
  }

  onExit = async values => {
    history.push(config.ROUTES.HOME);
  }

  render() {
    return (
      <LayoutResponsiveWSmall
        header={{
          className: "bg-primary",
          title: this.t("Sugerencias y Soporte"),
          left: { icon: 'arrow_left', action: () => history.push(config.ROUTES.INFO) }
        }}
        main={{
          className: "light-primary-bg text-gray-700",
        }}
      >
        <div className="flex justify-center items-center">
          <div className="mx-auto text-center">
            <h3 className="h3 mb-2 mt-5">
              <strong>Envianos tus dudas y sugerencias a</strong>
            </h3>
            <div className="">
              <div className="card white p-3 text-center">
                <p className="mb-2">
                  <strong>
                    <Icon className="block text-primary w-12 mb-2 mx-auto" name="support" />
                    Mail
                  </strong>
                  <a className="link text-primary" target="_blank" href="mailto:info@sharyco.com">
                    info@sharyco.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>

      </LayoutResponsiveWSmall>
    )
  }
}

export default withTranslation()(InfoAssistance);
