import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import config from '../../config';
import { notify } from '../../libraries/notifications';
import { history } from '../../routes';
import Swal from 'sweetalert2';

import Sidebar from '../../components/layout/Sidebar';
import Loader from '../../components/commons/Loader';
import Icon from '../../libraries/icons';
import LoanCard from '../../components/customs/LoanCard';
import BottomNav from '../../components/commons/BottomNav';
import ActivityElement from '../../components/customs/ActivityElement';

import productsAction from '../../context/products/actions';
import transactionsAction from '../../context/transactions/actions';
import MessagesActions from '../../context/messages/actions';
import internalsActions from '../../context/internals/actions';
import usersActions from '../../context/users/actions';
import notificationsActions from '../../context/notifications/actions';

import { capitalize } from '../../libraries/utils';
import AutoQuery from '../../components/commons/AutoQuery';
import { sub } from 'date-fns';
import LayoutResponsive from '../../components/layout/LayoutResponsive';

class Loans extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      loans: [],
      subtype: 'product',
      loans_history: [],
      loading: false,
      notifications: {
        product: false,
        favor: false,
      },
    }
  }

  componentDidMount() {
    const subtype = this.props.location?.state?.subtype
    if (subtype) this.setState({ subtype })

    console.log('Loans');
    this.getMyLoans();
    this.getNotifications()
  }

  getMyLoans = async () => {
    await this.props.onGetMyLoans(({ type: config.TRANSACTIONS.BORROW.TYPE, source: this.props.auth.user.id }));
    const { loans } = this.props;
    if (loans.error) {
      notify(this.t(loans.error.message));
    } else {
      this.setState({
        loans: loans.items.filter((loan) => (
          ![config.TRANSACTIONS.BORROW.STATUS.CANCELED,
          config.TRANSACTIONS.BORROW.STATUS.COMPLETED,
          ].includes(loan.status)
        )),
        loans_history: loans.items.filter((loan) => (
          [config.TRANSACTIONS.BORROW.STATUS.CANCELED,
          config.TRANSACTIONS.BORROW.STATUS.COMPLETED,
          config.TRANSACTIONS.BORROW.STATUS.ON_OWNER
          ].includes(loan.status)
        )),
      });
    }
  }

  getNotifications = async () => {
    await this.props.onGetAllNotifications({ target: this.props.auth.user.id })
    const notifications = this.props.notifications?.items;
    const productNotifications = notifications.filter(item => item.json_data && item.json_data.product && item.readed === 0).length;
    const favorNotifications = notifications.filter(item => item.json_data && item.json_data.favor && item.readed === 0).length;
    this.setState({
      notifications: {
        product: !!productNotifications,
        favor: !!favorNotifications,
      }
    })
  }



  rejectTrx = async (loan, status) => {

    const message = [
      this.t(`Estás rechazando el pedido`)
    ];

    const result = await Swal.fire({
      title: this.t("¿Cuál es el motivo?"),
      input: "text",
      text: message.join(''),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.t("Rechazar"),
      cancelButtonText: this.t("Cancel"),
      inputValidator: (value) => {
        if (!value) {
          return 'Por favor, escribir un motivo'
        }
      }
    })
      .then((result) => {
        return result
      });
    console.log('ANSWER', result);

    if (result && result.isConfirmed) {

      this.updateTrx(loan, status, result.value);

    }
  }


  sendCancelMail = async (loan, reject_reason) => {
    let mailData = {
      template: "borrow_cancel_user",
      locale: "es",
      to: loan.json_data.borrower?.email,
      first_name: loan?.json_data?.borrower.first_name,
      last_name: loan?.json_data?.borrower.last_name,
      params: {
        borrow_name: loan?.json_data?.product ? loan.json_data.product.name : loan.json_data.offer.name,
        owner_name: `${loan?.json_data?.owner?.first_name} ${loan?.json_data?.owner?.last_name}`,
        reason_name: reject_reason,
        // subject: "Préstamo cancelado",
        line_1: `${loan.json_data.borrower?.first_name} ${loan.json_data.borrower?.last_name}`,
        line_2: `${this.props.auth.user.first_name} ${this.props.auth.user.last_name}`,
        line_3: `${loan.json_data.product ? loan.json_data.product.name : loan.json_data.offer.name}`,
        line_4: reject_reason,
        //line_1: `Hola ${loan.json_data.borrower?.first_name} ${loan.json_data.borrower?.last_name}`,
        //line_2: `Te informamos que `,
        //line_3: " tuvo que cancelar el siguiente pedido que te solicitó ",
        //line_4: `${loan.json_data.product ? loan.json_data.product.name : loan.json_data.offer.name}`,
        //line_5: `Por la siguiente razón: ${reject_reason}`,
      }
    }

    await this.props.onSendMail(mailData);
    const internals = this.props.internals;
    if (internals.error) {
      notify(this.t(internals.error.message));
    }
  }

  updateTrx = async (loan, status, reject_reason) => {
    this.setState({ loading: true })
    let updateData = {
      id: loan.id,
      status,
    }
    if (status === config.TRANSACTIONS.BORROW.STATUS.CANCELED) {
      updateData.json_data = {
        canceled_by: "owner"
      }
      await this.props.onChangeProductState({
        id: loan.json_data.product ? loan.json_data.product.id : loan.json_data.offer.id,
        status: config.STATUS.PRODUCTS.AVAILABLE
      })
    }
    await this.props.onChangeTransactionState(updateData)
    //console.log('loan', this.props.loan);
    if (this.props.loan.error) {
      notify(this.t(this.props.loan.error.message));
    } else {
      let body = "";
      const productOrOffer = loan.json_data.product ? 'product' : 'offer';
      if (status === config.TRANSACTIONS.BORROW.STATUS.CANCELED) {
        if (productOrOffer === 'product') {
          body = " canceló tu pedido de: " + loan.json_data.product.name + " por el motivo: " + reject_reason
        } else {
          body = " canceló tu servicio de: " + loan.json_data.offer.name + " por el motivo: " + reject_reason
        }
      } else if (status === config.TRANSACTIONS.BORROW.STATUS.ACCEPTED) {
        if (productOrOffer === 'product') {
          body = " aceptó tu pedido de:  " + loan.json_data.product.name // The body is used to redirect at /notifications. Change it there too.

        } else {
          body = " aceptó tu servicio de:  " + loan.json_data.offer.name
        }
      }
      let notificationData = {
        type: config.MESSAGES.TYPE.NOTIFICATION,
        source: loan.json_data.owner.id,
        target: loan.json_data.borrower.id,
        related_to: loan.json_data.product ? loan.json_data.product.id : loan.json_data.offer.id,
        parent: " ",
        subject: loan.json_data.product ? loan.json_data.product.type : loan.json_data.offer.type,
        body: body,
        json_data: {
          product: loan.json_data.product ? loan.json_data.product : loan.json_data.offer,
          redirect_notification_route: config.ROUTES.LOANS,
        },
      }
      await this.props.onSaveNotification(notificationData);
      console.log('AFTER SAVE NOTIFICATION');
      const notification = this.props.message;
      if (notification.error) {
        notify(this.t(notification.error.message));
      }
      let template = loan.json_data.product ? "borrow_product_acepted" : "borrow_accepted"
      if (status === config.TRANSACTIONS.BORROW.STATUS.ACCEPTED) {
        let mailData = {
          template,
          locale: "es",
          to: loan.json_data.borrower.email,
          first_name: loan.json_data.borrower.first_name,
          last_name: loan.json_data.borrower.last_name,
          params: {
            borrow_name: loan?.json_data?.product ? loan.json_data.product.name : loan.json_data.offer.name,
            owner_name: `${loan?.json_data?.owner?.first_name} ${loan?.json_data?.owner?.last_name}`,
            // subject: "Aceptaron tu pedido de préstamo",
            line_1: `${this.props.auth.user.first_name} ${this.props.auth.user.last_name}`,
            line_2: loan.json_data.product ? loan.json_data.product.name : loan.json_data.offer.name,
            //link_app: config.ROUTES.USER.RETURNS,
          }
        }

        await this.props.onSendMail(mailData);
        const internals = this.props.internals;
        if (internals.error) {
          notify(this.t(internals.error.message));
        }
      }

      if (status === config.TRANSACTIONS.BORROW.STATUS.CANCELED) {
        await this.props.onChangeProductState({
          id: loan.json_data.product ? loan.json_data.product.id : loan.json_data.offer.id,
          status: config.STATUS.PRODUCTS.AVAILABLE
        })
        const product = this.props.product;
        console.log('AFTER PRODUCT STATUS CHANGING', product);
        if (product.error) {
          notify(this.t(product.error.message));
        } else {
          this.sendCancelMail(loan, reject_reason)
          Swal.fire('', this.t("Has cancelado el pedido"), 'success');
          this.getMyLoans();
        }
      } else {
        this.getMyLoans();
      }
    }

    this.setState({ loading: false })
  }

  cancelTrx = async (loan) => {
    console.log('CANCEL TRANSACTION', loan.id);
    const message = [
      this.t(`Estás cancelando el pedido de `),
      //this.t(`del ${loan.json_data.product ? "producto" : "servicio/oferta"}`),
      ` ${loan.json_data.product ? loan.json_data.product.name : loan.json_data.offer.name}`
    ];

    const result = await Swal.fire({
      title: this.t("¿Are you sure?"),
      text: message.join(''),
      input: "text",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.t("Confirmar"),
      cancelButtonText: this.t("Cancel"),
      inputValidator: (value) => {
        if (!value) {
          return 'Por favor, escribir un motivo'
        }
      }
    })
      .then((result) => {
        return result
      });
    console.log('ANSWER', result);

    if (result && result.isConfirmed) {
      this.updateTrx(loan, config.TRANSACTIONS.BORROW.STATUS.CANCELED, result.value);
    } else if (result && result.isDismissed) {
      Swal.fire('', this.t("Se canceló la acción"), 'error');
    }

  }

  gotoProduct = (p) => {
    let route = config.ROUTES.PRODUCTS.DETAIL
    let product_id = p.json_data.product ? p.json_data.product.id : p.json_data.offer.id
    if (p.sub_type === 'favor') route = config.ROUTES.FAVORS.DETAIL
    if (p.sub_type === 'service') route = config.ROUTES.SERVICES.DETAIL
    history.push({ pathname: route.replace(':id', product_id), state: { fromPath: config.ROUTES.USER.LOANS } })
  }

  render() {
    const { loans, loans_history, subtype, notifications } = this.state;
    // const getSubTypeString = (subtype) => {return (subtype === config.TRANSACTIONS.BORROW.SUB_TYPE.FAVOR ? "favor" : "servicio")}

    const products = loans.filter(l => l.sub_type === config.TRANSACTIONS.BORROW.SUB_TYPE.PRODUCT);
    const services = loans.filter(l => l.sub_type === config.TRANSACTIONS.BORROW.SUB_TYPE.SERVICE);
    const favors = loans.filter(l => l.sub_type === config.TRANSACTIONS.BORROW.SUB_TYPE.FAVOR);

    const options = [...Object.keys({ product: 'product', favor: 'favor', })].map((o) => { return { value: o.toLocaleLowerCase(), label: o === 'service' ? capitalize("favore") : this.t(capitalize(o.toLocaleLowerCase())) } });

    const getActions = (_loan) => {
      return _loan.json_data.product ?
        [{
          status: [config.TRANSACTIONS.BORROW.STATUS.NEW], className: 'btn-primary',
          title: this.t("Aceptar"),
          disabled: this.state.loading,
          onClick: (loan) => {
            if (loan.json_data.product || loan.json_data.offer) {
              this.updateTrx(loan, config.TRANSACTIONS.BORROW.STATUS.ACCEPTED)
            } else {
              this.updateTrx(loan, config.TRANSACTIONS.BORROW.STATUS.ACCEPTED)
            }

          }
        },
        {
          status: [config.TRANSACTIONS.BORROW.STATUS.NEW], className: 'btn-error border-none',
          title: this.t("Rechazar"),
          disabled: this.state.loading,
          onClick: (loan) => this.rejectTrx(loan, config.TRANSACTIONS.BORROW.STATUS.CANCELED)
        },
        {
          status: [config.TRANSACTIONS.BORROW.STATUS.ACCEPTED], className: 'btn-error',
          title: this.t("Cancelar entrega"),
          disabled: this.state.loading,
          onClick: (loan) => this.cancelTrx(loan)
        },
        {
          status: [config.TRANSACTIONS.BORROW.STATUS.ON_OWNER,], className: 'btn-primary btn-sm ',
          title: this.t("Confirmar Devolución"),
          disabled: this.state.loading,
          onClick: (loan) => {
            if (loan.json_data.product) {
              history.push(config.ROUTES.USER.CONFIRM_RETURNED.replace(':id', loan.id))
            } else {
              this.updateTrx(loan, config.TRANSACTIONS.BORROW.STATUS.COMPLETED)
            }
          }
        },
        {
          status: [config.TRANSACTIONS.BORROW.STATUS.ON_USER], className: 'btn-error',
          title: this.t("Devolución pendiente"),
          disabled: true,
        },
        ] : [
          {
            status: [config.TRANSACTIONS.BORROW.STATUS.NEW], className: 'btn-primary',
            title: this.t("Aceptar"),
            disabled: this.state.loading,
            onClick: (loan) => {
              this.updateTrx(loan, config.TRANSACTIONS.BORROW.STATUS.ACCEPTED)
            }
          },
          {
            status: [config.TRANSACTIONS.BORROW.STATUS.NEW], className: 'btn-error border-none',
            title: this.t("Rechazar"),
            disabled: this.state.loading,
            onClick: (loan) => this.rejectTrx(loan, config.TRANSACTIONS.BORROW.STATUS.CANCELED)
          },
          {
            status: [config.TRANSACTIONS.BORROW.STATUS.ACCEPTED], className: 'btn-error',
            title: this.t("Cancelar"),
            disabled: this.state.loading,
            onClick: (loan) => this.cancelTrx(loan)
          },
          {
            status: [config.TRANSACTIONS.BORROW.STATUS.ON_USER, config.TRANSACTIONS.BORROW.STATUS.ON_OWNER], className: 'btn-primary btn-sm ',
            //title: this.t(`Ya realicé el ${getSubTypeString(_loan.sub_type)}`),
            title: `Calificar`, // ${getSubTypeString(_loan.sub_type)}
            disabled: this.state.loading,
            onClick: (loan) => {
              history.push(config.ROUTES.USER.CONFIRM_RETURNED.replace(':id', loan.id))
              //if(loan.json_data.product){
              //  history.push(config.ROUTES.USER.CONFIRM_RETURNED.replace(':id', loan.id))
              //} else {
              //  this.updateTrx(loan, config.TRANSACTIONS.BORROW.STATUS.COMPLETED)
              //}
            }
          },
        ];
    }
    return (
      <LayoutResponsive
        main={{ className: "pb-14" }}
        header={{
          logo: true,
          //content: <NavButtonBar options={buttonBarUpper} active={topNav} onClick={this.onNavBarClick} />
        }}
      >
        {/* <<Sidebar
          header={{
            className: '',
            title: this.t("Lo que presté"),
          }}
        />> */}
        <AutoQuery getData={this.getMyLoans} />

        <div className="container mx-auto h-full">
          <div>
            <div className='flex justify-between border-t'>
              {options.map((o, i) => (
                <div
                  key={i}
                  className={"px-4 py-3 text-center border-b-4 border-transparent font-medium flex-1 rounded-none " + (o.value === subtype ? " border-b-secondary font-semibold text-base-content " : " text-neutral ") + ""}
                  onClick={() => this.setState({ subtype: o.value }, () => console.log(products, services, favors))}
                >
                  <h5 className='flex items-center justify-center gap-2 whitespace-nowrap'>{this.t(
                    (o.value === config.TRANSACTIONS.BORROW.SUB_TYPE.PRODUCT) ? "Products" : "Favors")}
                    {notifications[o.value] &&
                      <div className="badge badge-sm bg-error text-white border-none" />
                    }
                  </h5>
                </div>
              ))}
            </div>
            {this.props.loans.loading && (<Loader />)}
            {!this.props.loans.loading && loans.length === 0 && (
              <div className="text-center p-10 text-gray-400">
                <Icon className="h-14 w-14 mx-auto mb-2" name="loans" />
                <p className="mb-1">{this.t("No hay préstamos")}</p>
              </div>
            )}
            <div className="p-3">
              {subtype === 'product' && (
                products.map((loan, index) => (
                  <LoanCard className="mb-4" key={index} loan={loan} actions={getActions(loan)}
                    statuses={config.STATUS.TRANSACTIONS}
                    onClick={() => {
                      if (loan.json_data.product) {
                        history.push({
                          pathname: config.ROUTES.PRODUCTS.DETAIL.replace(':id', loan.json_data.product.id),
                          state: { fromPath: config.ROUTES.USER.LOANS }
                        })
                      } else {
                        history.push({
                          pathname: config.ROUTES.FAVORS.DETAIL.replace(':id', loan.json_data.offer.id),
                          state: { fromPath: config.ROUTES.USER.LOANS }
                        })
                      }
                    }}
                  />
                ))
              )}
              {subtype === 'service' && (
                services.map((loan, index) => (
                  <LoanCard className="mb-4" key={index} loan={loan} actions={getActions(loan)}
                    statuses={config.STATUS.TRANSACTIONS}
                    onClick={() => {
                      if (loan.json_data.product) {
                        history.push({
                          pathname: config.ROUTES.PRODUCTS.DETAIL.replace(':id', loan.json_data.product.id),
                          state: { fromPath: config.ROUTES.USER.LOANS }
                        })
                      } else {
                        history.push({
                          pathname: config.ROUTES.FAVORS.DETAIL.replace(':id', loan.json_data.offer.id),
                          state: { fromPath: config.ROUTES.USER.LOANS }
                        })
                      }
                    }}
                  />
                ))
              )}
              {subtype === 'favor' && (
                favors.map((loan, index) => (
                  <LoanCard className="mb-4" key={index} loan={loan} actions={getActions(loan)}
                    statuses={config.STATUS.TRANSACTIONS}
                    onClick={() => {
                      if (loan.json_data.product) {
                        history.push({
                          pathname: config.ROUTES.PRODUCTS.DETAIL.replace(':id', loan.json_data.product.id),
                          state: { fromPath: config.ROUTES.USER.LOANS }
                        })
                      } else {
                        history.push({
                          pathname: config.ROUTES.FAVORS.DETAIL.replace(':id', loan.json_data.offer.id),
                          state: { fromPath: config.ROUTES.USER.LOANS }
                        })
                      }
                    }}
                  />
                ))
              )}

            </div>
          </div>
          <div className="mb-40">
            {loans_history.length > 0 && <h4 className="px-4 text-gray-400 mb-2">{this.t("Historial")}</h4>}
            <div className="bg-white shadow-lg">
              {loans_history.map((h, index) => (
                <ActivityElement
                  key={index} activity={h} user={this.props.auth.user.id} statuses={config.STATUS.TRANSACTIONS}
                  onClick={() => { this.gotoProduct(h) }}
                />
              ))}
            </div>
          </div>
        </div>
        <br/>
        {/* <div><br/></div> fixme css: esto está para que el BottomNav no tape el historial */}
        {/* <BottomNav /> */}
      </LayoutResponsive>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    loans: state.transactions.list,
    loan: state.transactions.current,
    product: state.products.current,
    message: state.messages.current,
    internals: state.internals,
    users: state.users.users,
    notifications: state.notifications.list,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetMyLoans: (params) => dispatch(transactionsAction.getAll(params)),
    onChangeTransactionState: (params) => dispatch(transactionsAction.saveOrUpdate(params)),
    onChangeProductState: (params) => dispatch(productsAction.saveOrUpdate(params)),
    onSaveNotification: (params) => dispatch(MessagesActions.saveOrUpdate(params)),
    onSendMail: (params) => dispatch(internalsActions.sendMail(params)),
    onGetAllNotifications: (params) => dispatch(notificationsActions.getAll(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Loans));




