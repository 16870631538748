import React from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { withTranslation } from "react-i18next";
import { ToastContainer, notify } from '../libraries/notifications';

import { history } from '../routes';
import config from '../config';

import SearchInput from '../components/forms/SearchInput';
import BottomNav from '../components/commons/BottomNav';
import Icon from "../libraries/icons";
import Loader from '../components/commons/Loader';


import productsAction from '../context/products/actions';
import servicesAction from '../context/services/actions';
import favorsAction from '../context/favors/actions';
import donationsActions from "../context/donations/actions";
import recyclingActions from "../context/recycling/actions";
import volunteeringActions from "../context/volunteering/actions";
import actionsAction from "../context/actions/actions";
import categoriesAction from '../context/categories/actions';
import actionChallenges from '../context/challenges/actions'

import LayoutWithSidebar from '../components/layout/LayoutWithSidebar';
import LayoutSmall from '../components/layout/LayoutSmall';
import SearchResults from '../components/customs/SearchResults';
import LayoutResponsive from '../components/layout/LayoutResponsive';

class SearchOffers extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {   
      products: [],
      services: [],
      favors: [],
      recycled_products:[],
      donations:[],
      volunteering_jobs: [],
      allOffers:[],
      loading:false,
      search: "", 

      actions:[],
      challenges: [],

    }
  }
  
  componentDidMount() {
    console.log('SEARCH OFFERS');
  }

  getProducts = async ()=> {
    const {search} = this.state
    const params = { status: config.STATUS.PRODUCTS.AVAILABLE, unique_id: this.props.auth.user.unique_id, type: [config.TYPES.OFFERS.PRODUCTS, config.TYPES.OFFERS.SERVICES, config.TYPES.OFFERS.FAVORS] }

    if (search && search !== ""){
      params.name = `%${search.toLowerCase()}%`;
    }
    await this.props.onGetProducts(params);
    const { products } = this.props;
    if (products.error) {
      notify(this.t(products.error.message));
    } else {
      this.setState({ 
        products: products.items.filter(p => p.owner !== this.props.auth.user.id)
      })
    }    
  }

  getServices = async ()=> {
    const {search} = this.state
    const params = { status: config.STATUS.PRODUCTS.AVAILABLE, unique_id: this.props.auth.user.unique_id }

    if (search && search !== ""){
      params.name = `%${search.toLowerCase()}%`;
    }
    await this.props.onGetServices(params);
    const { services } = this.props;
    if (services.error) {
      notify(this.t(services.error.message));
    } else {
      this.setState({ 
        services: services.items.filter(s => s.owner !== this.props.auth.user.id)
      })
    }    
  }

  getFavors = async ()=> {
    const {search} = this.state
    const params = { status: [config.STATUS.PRODUCTS.AVAILABLE, config.STATUS.PRODUCTS.BORROWED], unique_id: this.props.auth.user.unique_id }

    if (search && search !== ""){
      params.name = `%${search.toLowerCase()}%`;
    }
    await this.props.onGetFavors(params);
    const { favors } = this.props;
    if (favors.error) {
      notify(this.t(favors.error.message));
    } else {
      this.setState({
        favors: favors.items.filter(f => f.owner !== this.props.auth.user.id)
      })
      
    }    
  }

  getDonations = async ()=> {
    const {search} = this.state
    const params = {}

    if (search && search !== ""){
       params.name = `%${search.toLowerCase()}%`;
    }

    await this.props.onGetAllDonations(params);
    const { donations } = this.props;
    if (donations.error) {
      notify(this.t(donations.error.message));
    } else {
      this.setState({ 
        donations: donations.items
      })
    }    
  }

  getRecycling = async ()=> {
    const {search} = this.state
    const params = {}

    if (search && search !== ""){
       params.name = `%${search.toLowerCase()}%`;
    }
    await this.props.onGetAllRecycling(params);
    const { recycled_products } = this.props;
    if (recycled_products.error) { 
      notify(this.t(recycled_products.error.message));
    } else {
      this.setState({ 
        recycled_products: recycled_products.items
      })
    }    
  }

  
  getActions = async () => {
    const {search} = this.state
    const params = {}
    if (search && search !== ""){
      params.name = `%${search.toLowerCase()}%`;
   }
   await this.props.onGetAllActions({...params, enabled: 1});
   const { actions } = this.props;

    if (actions.error) {
      notify(this.t(actions.error.message));
    } else {
      const mappedWithCardType = actions.items.map(action => ({
        ...action,
        cardType: "action"
      }))
      this.setState({ actions: mappedWithCardType });
    }    
  }
  getChallenges = async () => {
    const {search} = this.state
    let params = {}
    if (search && search !== ""){
      params.name = `%${search.toLowerCase()}%`;
   }
    await this.props.onGetAllChallenges({...params, enabled: 1})
    const { challenges } = this.props;
    if (challenges.error) {
      notify(this.t(challenges.error.message));
    } else {
      const mappedWithCardType = challenges.items.map(challenge => ({
        ...challenge,
        cardType: "challenge"
      }))
      this.setState({ challenges: mappedWithCardType });
    }    
  }

  getVolunteering = async ()=> {
    const {search} = this.state
    const params = {}

    if (search && search !== ""){
       params.name = `%${search.toLowerCase()}%`;
    }
    await this.props.onGetAllVolunteering(params);
    const { volunteering_jobs } = this.props;
    if (volunteering_jobs.error) { 
      notify(this.t(volunteering_jobs.error.message));
    } else {
      this.setState({ 
        volunteering_jobs: volunteering_jobs.items
      })
    }    
  }

  concatOffers = () => {
    const {products,services,favors,recycled_products,donations,volunteering_jobs, actions, challenges } = this.state
    const sortedResults = [
      ...products,
      ...services,
      ...favors,
      ...recycled_products,
      ...donations,
      ...volunteering_jobs,
      ...actions,
      ...challenges
    ]
    sortedResults.sort((a, b) => a.name.localeCompare(b.name))
    this.setState({ allOffers:sortedResults , loading: false})
  }


  searchClear = (form) => {
    form.change('search', undefined);
    this.setState({search: "", allOffers:[]})
  }

  searchOnClick = async (values) => {
    console.log("Search on Click", values.search);
    if (this.state.search !== values.search) this.setState({ search: values.search || '' }, async () => {
      this.setState({ loading: true });
      await this.getProducts();
      //this.getServices();
      //this.getFavors();
      await this.getActions();
      await this.getChallenges()
      await this.getDonations();
      await this.getRecycling();
      await this.getVolunteering();
      this.concatOffers();

    });
    
  };

 
  render() {
    const { allOffers,search, loading } = this.state;

    return (
      <LayoutResponsive
        main={{ className: ""}}
        header={{ 
          className: "hidden",
        }}
        container={{ className: "px-0"}}
      >
      
      <main className="min-h-full">
        <ToastContainer/>
        <div className="bg-white shadow">
          <div className="p-4">
          <Form initialValues={{}} onSubmit={this.searchOnClick}>
            {({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit} className="w-full ">
                <Field name="search" component={SearchInput} placeholder="Buscar"/* {this.t("Search")} */ 
                  onClick={this.searchOnClick} onClearClick={() => this.searchClear(form)}
                  inputClassName='bg-base-200 rounded-full'
                />
              </form>
            )}
          </Form>
          </div>
        </div>
        <section className="p-4">
          <div>
            { loading && (<Loader spinnerClassName = "h-12 w-12 text-primary"/>) }
            { !loading  && allOffers.length === 0 && (
              <div className="text-center p-10">
                <Icon className="h-24 w-24 mx-auto mb-3" name="stethoscope" />
                <h4 className="mb-1">{search && search !== "" ? this.t("No se encontraron ofertas similares"):this.t("¿Qué estás buscando?") }</h4>
              </div>
            )}
            <div className="flex flex-col gap-2">
            <SearchResults offers={allOffers} />
            </div>
          </div>
        </section>
        {/* <BottomNav/> */}
      </main>
      
      </LayoutResponsive>  
    ) 
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    products: state.products.list,
    services: state.services.list,
    favors: state.favors.list,
    donations:state.donations.list,
    recycled_products:state.recycling.list,
    volunteering_jobs: state.volunteering.list,
    /* requests: state.transactions.list, */
    actions:state.actions.list,
    categories : state.categories.list,
    challenges: state.challenges.list,
    
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetProducts: (params) => dispatch(productsAction.getAll(params)),
    onGetServices: (params) => dispatch(servicesAction.getAll(params)),
    onGetFavors: (params) => dispatch(favorsAction.getAll(params)),
    onGetAllDonations: (params) => dispatch(donationsActions.getAll(params)),
    onGetAllRecycling: (params) => dispatch(recyclingActions.getAll(params)),
    onGetAllVolunteering: (params) => dispatch(volunteeringActions.getAll(params)),
   /*  onGetAllRequests: (params) => dispatch(transactionsAction.getAll(params)), */

   onGetAllActions: (params) => dispatch(actionsAction.getAll(params)),
   onGetAllCategories: (params) => dispatch(categoriesAction.getAll(params)),
   onGetAllChallenges: (params) => dispatch(actionChallenges.getAll(params)),

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(SearchOffers));

