import React from 'react';
import Icon from '../../libraries/icons';

const SearchInput = ({ input, meta, label, ...rest }) => {
  return (
    <div className={"form-control " + (rest.className ? rest.className : "")}>
      {label && (
        <label htmlFor={input.name} className={"label " + (rest.labelClassName ? rest.labelClassName : "")}>
          <span className="label-text">{label}</span>
        </label>
      )}
      <div className="relative">
        <input
          {...input}
          type="text"
          className={"input w-full " 
            + (rest.inputClassName ? rest.inputClassName : " ")
          }
          placeholder={rest.placeholder ? rest.placeholder : ""}
          disabled={rest.disabled}
          readOnly={rest.readOnly}
          autoFocus={rest.autoFocus}
        />
        {input.value !== '' && (<button type="button" className={"absolute right-0 " + (rest.classButton ? rest.classButton : "mt-0 mr-9")  }
          onClick={() => rest.onClearClick && rest.onClearClick()}
        >
          <Icon className="w-7 h-7 pr-2 pt-2 opacity-40" name="close" />
        </button>)}
        <button type="button" className={"absolute right-0 " + (rest.classButton ? rest.classButton : "mt-0 mr-3")  }
          onClick={() => rest.onClick && rest.onClick({ search: input.value })}
        >
          <Icon className="w-7 h-7 pr-2 pt-2 opacity-40" name="search" />
        </button>
      </div>
    </div>
  )
}

export default SearchInput;