import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from "react-final-form";
import { WhenFieldChangesDo } from '../../libraries/forms';
import { ToastContainer, notify } from '../../libraries/notifications';
import config from '../../config';
import { history } from '../../routes';
import { capitalize, toBool, capitalizePhrase } from '../../libraries/utils';

import Icon from '../../libraries/icons';
import FeedCard from '../../components/customs/FeedCard';
import ButtonIcon from '../../components/commons/ButtonIcon';
import feedHome from '../../assets/icons/home/feed.svg'



import transactionsAction from "../../context/transactions/actions";
import companiesAction from '../../context/companies/actions';
import messagesActions from '../../context/messages/actions';
import Loader from '../../components/commons/Loader';
import SelectInput from '../../components/forms/SelectInput';
import Instructive from '../../components/commons/Instructive';

class Feed extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      feed: [],
      unique_id: this.props.auth.user.unique_id,
      viewAll: true,
    }
  }
  
  componentDidMount() {
    //FIXME: sacar esto ya no hay dos tipos de usuario distintos
    if(this.props.auth.user.type.includes(config.USER_TYPE.INITIATIVES)){
      history.push(config.ROUTES.VOLUNTEERING_ATTENDANCE)
      //FIXME: esto da un error en attendance porque arranca a cargar el getTransactions este de abajo
      //para solucionar hay que descomentarle el return de abajo no lo hago ahora porque no tengo tiempo de testear
      return 
    }
    if(this.props.auth.user.type.includes(config.USER_TYPE.BENEFITS)){
      history.push(config.ROUTES.BENEFITS_CONSUME)
      return
    }
    console.log('FEED PAGE');
    //FIXME: el error de la request de transaccion fantasma que da error en el login creo que se soluciona
    //deslogueando acá en caso de que no esté el auth.user etc
    if(this.props.auth.user?.ancestors){
      let companiesIds = this.props.auth.user.ancestors.companies.map(c => c.id)
      this.setState({companiesIds, unique_id: companiesIds, viewAll: true}, this.getData)      
    }
  }

  getData = async () => {
    this.getAllTransactions();    
  }


  getAllTransactions = async () => {
    this.setState({loadingFeed: true})

    const params = {
      type: [
        config.TRANSACTIONS.INITIATIVE.TYPE,
        config.TRANSACTIONS.ACTION.TYPE,
        config.TRANSACTIONS.BORROW.TYPE,
        config.TRANSACTIONS.BENEFIT.TYPE,
      ],
      status: [
        "attended",
        "completed",
        //"accepted",
        "on_user",
        "on_owner",
        "review",
        "consumed",
      ],
      owner: this.state.unique_id,
      page_size: 50,
    }
    await this.props.onGetAllTransactions(params);
    const { transactions } = this.props;
    if (transactions.error) { 
      notify(this.t(transactions.error.message));
    } else {
      //console.log("TRXS", transactions)
      //FIXME: TEMPORAL PARA QUE NO APAREZCA COMENZARON A USAR SHARYCO
      //let feed = transactions.items.filter(t => t.sub_type !== "04CF0F2F-9B15-8F31-3760-1BB6C46192E6")
      let feed = transactions.items.filter(t => !["both", "user"].includes(t.sub_type)).filter(t=>!(t.type=='action'&& t.json_data.coins==0 && t.json_data.points==0))
      this.setState({
        feed
        //feed: transactions.items
      });


      //console.log("TRXS", feed)

    }

    this.setState({loadingFeed: false})
  }
  
  async onLike(id) {
    const owner = this.props.auth.user.id
    await this.props.onGetTransaction({id})
    const post = this.props.transaction.item
    let dislike = false;
    if(this.props.transaction.error) {
      notify(this.props.transaction.error.message)
    } else {
      //console.log(post)
      let likes = post.json_data?.likes?.length ? post.json_data?.likes?.split(',') : []
      if(likes.includes(owner)) {
        dislike = true;
        let new_likes = likes.filter(e => e !== owner && e.length).toString()
        const obj = {
          id: post.id,
          json_data: {
            likes: new_likes
          }
        }
        await this.props.modifyTransaction(obj)
      } else {
        const obj = {
          id: post.id,
          json_data: {
            likes: [...likes, owner].toString()
          }
        }
        await this.props.modifyTransaction(obj)
      }
      
      const {transaction} = this.props
      if(transaction.error) {
        notify(transaction.error.message)
      } else {
        this.setState({feed: this.state.feed.map(e => e.id === post.id ? transaction.item : e)})

        let target = transaction?.item?.json_data?.borrower ? 
        transaction?.item?.json_data?.borrower.id || JSON.parse(transaction?.item?.json_data?.borrower).id : 
        transaction?.item?.json_data?.user.id || JSON.parse(transaction?.item?.json_data?.user).id;

        if(!dislike && target !== owner){
          const obj = {
            body: " le dió like a tu publicación",
            json_data: {
              post: post.id,
              redirect_notification_route: config.ROUTES.FEED.DETAIL.replace(':id', post.id),
            },
            parent: " ",
            source: owner,
            subject: "like",
            target,
            type: config.MESSAGES.TYPE.NOTIFICATION,
          }
          await this.props.onSendNotification(obj);
          const { message } = this.props;
          if (message.error) {
            notify(this.t(message.error.message));
          } 
        }
      }
    }
  }


  setViewAll = (viewAll) => {
    let unique_id = this.props.auth.user.unique_id
    this.setState({viewAll, unique_id: viewAll ? this.state.companiesIds : unique_id}, this.getData)
  }

  onSubmit = (value) => {
    this.setViewAll(toBool(value));
  }

  render() {
    const { feed, viewAll } = this.state;

    const options = [{ value: true, label: capitalize(this.t('all the accounts')) },{ value: false, label: capitalize(this.t('my office')) }];

    return (
      <div className="w-full mx-auto">
        <section className="p-3">
          {/* Texto que iría en desktop */}
          {/* <div className='flex justify-start mx-auto'>
            <Icon className={"h-6 w-6 text-secondary"} alt='' name={feedHome}/>
            <span className={' px-2 block font-bold text-md text-base-content'} >
                {capitalizePhrase('muro')}
            </span>
          </div> */}
          <div className='pb-2 border-b'>
            <Form initialValues={{ viewAll }} onSubmit={this.onSubmit}>
              {({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit}>
                  <Field name="viewAll" component={SelectInput} selectClassName="select-ghost select-sm w-40" options={options} noError={true} />
                  <WhenFieldChangesDo field="viewAll" action={({ field, value, values }) => { this.onSubmit(value); }} />
                </form>
              )}
            </Form>
          </div>
        </section>
        <section className="p-3">
          <Instructive
            slides={[
              {
                title:"Muro",
                body:"Podés ver todas las acciones que realizan tus compañeros e interactuar con ellos.",
                img: "feed"
              },
            ]}
            instructive={"show_feed"}
          >
            { !this.state.loadingFeed && feed.length === 0 && (
              <div className="text-center p-10">
                <Icon className="h-24 w-24 mx-auto mb-3" name="" />
                <h4 className="h4 text-primary mb-1">{this.t("Oh, no hay actividad")}</h4>
              </div>
            ) }
            <div className="tab-content">
              <div className="grid gap-4">
                {this.state.loadingFeed && (<Loader/>)}
                { feed && feed.map((f, index) => {
                    //console.log("FEED", f.id, f.json_data?.action?.name)
                    if(f.archived === 1) return null
                    return (
                      <FeedCard 
                        key={index}
                        route={config.ROUTES.FEED.DETAIL.replace(':id', f.id)}
                        data={f}
                        owner={this.props.auth.user.id}
                        onClickLikeIt={() => this.onLike(f.id)}
                      />
                    )
                  })
                }
              </div>
            </div>
          </Instructive>
        </section>

      </div>
    ) 
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    transactions: state.transactions.list,
    transaction: state.transactions.current,
    company: state.companies.current,
    companies: state.companies.list,
    message: state.messages.current,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAllTransactions: (params) => dispatch(transactionsAction.getAll(params)),
    onGetTransaction: (params) => dispatch(transactionsAction.get(params)),
    modifyTransaction: (params) => dispatch(transactionsAction.saveOrUpdate(params)),
    deleteTransaction: (params) => dispatch(transactionsAction.del(params)),
    onGetCompany: (id) => dispatch(companiesAction.get(id)),
    onGetCompanies: (params) => dispatch(companiesAction.getAll(params)),
    onSendNotification: (params) => dispatch(messagesActions.saveOrUpdate(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Feed));

