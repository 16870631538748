import React from 'react';
import { withTranslation } from 'react-i18next';
import { history } from '../../routes';
import config from '../../config';

import LayoutSmall from '../../components/layout/LayoutSmall';
import { capitalize } from '../../libraries/utils';
import LayoutResponsiveWSmall from '../../components/layout/LayoutResponsiveWSmall';


class InfoPrivacy extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
    }
  }

  componentDidMount() {
    console.log('TERMS PAGE');
  }

  onExit = async values => {
    history.push(config.ROUTES.HOME)
  }

  render() {
    return (
      <LayoutResponsiveWSmall
        header={{
          classNameName: "bg-primary",
          title: capitalize(this.t("privacy policies")),
          left: {
            icon: 'arrow_left', action: () => {
              history.push({ pathname: this.props.location?.state?.fromPath || config.ROUTES.INFO })
            }
          }
        }}
        main={{
          classNameName: "light-primary-bg text-gray-700",
        }}
      >
        <div className='mt-10'>
          <br />
          De conformidad con la Ley Nº 25.326 de Protección de los Datos Personales, y a nuestra propias políticas de autorregulación, mediante las presente Política de Privacidad, SHARYCO S.A., CUIT 30717266419 (“Sharyco”), desea informar a todas las personas (los “Usuarios”) que utilizan nuestra App Móvil Sharyco, el sitio web sharyco.com, y/o cualquier otra plataforma digital que se pueda crear en el futuro (el “Sitio”) acerca de su política de protección de datos de carácter personal, para que los Usuarios determinen libre y voluntariamente si desean facilitar a Sharyco los datos personales que se les puedan requerir o que se puedan obtener sobre los mismos con ocasión de su acceso y uso del Sitio.

          Este documento es parte integrante de los Términos y Condiciones Generales de Sharyco. Mediante la aceptación de los Términos y Condiciones Generales en el momento de la registración el usuario acepta las políticas aquí contenidas.

          Sharyco podrá modificar en cualquier momento los términos y condiciones de estas Políticas de Privacidad y confidencialidad y/o las prácticas de envío de e-mails. Si Sharyco decide introducir algún cambio material a estas Políticas de Privacidad, los Usuarios serán notificados publicando una versión actualizada de las Políticas en esta sección o mediante el envío de un e-mail o informándolos en la página principal u otras secciones del Sitio para mantenerte actualizado de los cambios realizados.

          Si Sharyco hiciera cambios materiales en la forma que los Datos Personales son administrados, notificará por e-mail a los Usuarios para que puedan tomar una decisión informada respecto si aceptan o no que sus Datos Personales sean utilizados de esa forma. Si no aceptara esos términos, en ese caso quedará disuelto el vínculo contractual y sus Datos Personales no serán usados de otra forma que la que fue informada al momento de recabarse.

          Se informa que la Dirección Nacional de Protección de Datos Personales, Órgano de Control de la Ley Nº 25.326, tiene la atribución de atender las denuncias y reclamos que se interpongan con relación al incumplimiento de las normas sobre protección de datos personales.

          <br /><br />
          <strong>1. OBJETO Y FINALIDAD DE LA OBTENCIÓN DE DATOS PERSONALES</strong>
          <br /><br />
          Sharyco recoge datos personales de los Usuarios del Sitio (“Datos Personales”) a través de varios medios, como por ejemplo, a través del formulario de registro del Sitio.

          Salvo que se indique otra cosa, sin perjuicio de que la entrega de los Datos Personales facilitados a Sharyco es voluntaria, la falta de entrega de dichos Datos Personales podrá afectar a la calidad de los servicios proporcionados por Sharyco.

          Los Datos Personales de los Usuarios se incluyen en un fichero automatizado de Sharyco con vistas a, entre otros, mantener una adecuada relación con los Usuarios; facilitar el acceso al servicio prestado por Sharyco; prestar, gestionar, administrar, ampliar y mejorar los servicios y/o contenidos ofrecidos en Sitio; así como para atender adecuadamente cualquier consulta o solicitud de información planteada por los Usuarios y, en su caso, para el envío de información sobre los servicios o actividades de Sharyco que pudieran ser del interés de los Usuarios.

          El fichero de Datos Personales de los usuarios de Sharyco reside en una base de datos provista por Amazon Web Services. El usuario al registrarse en Sharyco confirma que está informado de la residencia de este fichero y autoriza esta transferencia internacional de sus datos.


          Salvo su consentimiento expreso en contrario, Sharyco podrá remitir publicidad y comunicaciones con fines de venta u otras de naturaleza comercial a los Usuarios. Para estos efectos no entrará en el concepto de publicidad y comunicaciones con fines de venta, los newsletters que ocasionalmente pueda transmitir Sharyco a los Usuarios.

          Si posteriormente, el Usuario desea revocar su consentimiento otorgado para recibir información comercial vía correo electrónico o por cualquier otro medio similar o equivalente, podrá comunicarlo, enviando un correo electrónico a info@sharyco.com, sin perjuicio de los derechos de acceso, rectificación, cancelación y oposición que le asisten y que podrá ejercitar de conformidad con lo dispuesto en el apartado 7 de la presente Política de Privacidad.


          <br /><br />
          <strong>2. COOKIES</strong>
          <br /><br />
          Sharyco utiliza cookies para identificar a los Usuarios que visitan o utilizan el Sitio, para recordar las preferencias de los Usuarios y para proporcionar servicios personalizados, así como para hacer un seguimiento de la utilización del Sitio. Se establece que la instalación, permanencia y existencia de las cookies en la computadora del Usuario depende de su exclusiva voluntad y puede ser eliminada de su computadora cuando el Usuario así lo desee. Sin embargo, determinadas áreas o servicios del Sitio podrían no estar disponibles en caso de elegir esta opción.

          Aceptando está Política de Privacidad Sharyco podrá hacer uso de la información referente al Usuario contenida en las cookies derivadas de la instalación/utilización del Sitio por parte del Usuario.

          Asimismo, el Sitio puede incluir cookies de terceros proveedores de servicios y/o contenidos. Los Usuarios podrán aceptar o rechazar el uso de cookies y en consecuencia Sharyco no será en ningún caso responsable por cualesquiera actividades de tratamiento llevadas a cabo por terceros en relación con los Datos Personales recogidos a través de las cookies introducidas por los mismos en el Sitio.

          <br /><br />
          <strong>3. WEB BEACON</strong>
          <br /><br />
          Un Web beacon es una imagen electrónica, también llamada single-pixel (1 x 1) o pixel transparente, que es colocada en código de una página Web. Un Web beacon tiene finalidades similares a las Cookies. Adicionalmente un Web beacon es utilizado para medir patrones de tráfico de los usuarios de una página a otra con el objeto de maximizar como fluye el tráfico a través de la web. El Usuario y el visitante del Sitio de Sharyco conoce y acepta que Sharyco podrá utilizar un sistema de seguimiento mediante la utilización de Web beacons.

          <br /><br />
          <strong>4. SPAM</strong>
          <br /><br />
          Sharyco y sus usuarios no aceptan conductas consideradas "spamming", ya sea en opiniones, preguntas, respuestas y/o el envío no solicitado de correos electrónicos. Queda absolutamente prohibido el envío indiscriminado de mensajes de cualquier naturaleza entre los usuarios de Sharyco.
          Sharyco podrá suspender o inhabilitar definitivamente a aquellos usuarios que violen esta política.


          <br /><br />
          <strong>5. PRESTACIÓN DE SERVICIOS POR PARTE DE TERCEROS</strong>
          <br /><br />
          Sharyco puede poner los Datos Personales o parte de ellos a disposición de terceros prestadores de servicios, como por ejemplo los prestadores de servicios de logística, otras empresas propiedad del grupo empresarial al que pertenece Sharyco, empresas informáticas y proveedores de pago, tales como Paypal, Webpay, Visa, Mastercard, American Express, que podrán estar ubicadas en Argentina o en otros países, algunos de los cuales pueden no ofrecer un nivel de protección equivalente al que existe en Argentina con el fin del adecuado mantenimiento y funcionamiento del Sitio así como para la gestión de los pagos abonados por los Usuarios. Los Usuarios autorizan expresamente la transferencia de sus Datos Personales a dichos terceros, con las finalidades anteriormente indicadas.

          Si Sharyco decidiera revelar o compartir Datos Personales con terceros que no son proveedores de servicios o empresas aliadas, afiliadas o relacionadas con Sharyco, requerirá el consentimiento de los Usuarios.


          <br /><br />
          <strong>6. SEGURIDAD DE LA INFORMACIÓN</strong>
          <br /><br />
          Sharyco manifiesta y garantiza que realizará en todo momento sus mejores esfuerzos para mantener la seguridad y confidencialidad de los Datos Personales y que continuará mejorando las medidas de seguridad adoptadas en línea con cualesquiera desarrollos legales y tecnológicos.

          Sharyco está obligado a cumplir con toda la normativa aplicable en materia de medidas de seguridad aplicables a los Datos Personales. Adicionalmente, Sharyco usa los estándares de la industria entre materia de protección de la confidencialidad de sus Datos Personales, incluyendo, en otras medidas, cortafuegos ("firewalls") y Secure Socket Layers ("SSL"). Sharyco considera a los datos de sus Usuarios como un activo que debe ser protegido de cualquier pérdida o acceso no autorizado. Sin embargo, es necesario tener muy en cuenta que la seguridad perfecta no existe en Internet.  Por ello, Sharyco no se hace responsable por interceptaciones ilegales o violación de sus sistemas o bases de datos por parte de personas no autorizadas. Sharyco tampoco se hace responsable por la indebida utilización de la información obtenida por esos medios.

          <br /><br />
          <strong>7. DERECHOS DE LOS USUARIOS</strong>
          <br /><br />
          Los Usuarios tienen reconocidos y podrán ejercitar los derechos de acceder, cancelar y actualizar sus Datos Personales, incluyendo su dirección de e-mail, así como a oponerse al tratamiento de la misma y a ser informado de las cesiones llevadas a cabo, todo ello de conformidad a lo dispuesto en la normativa aplicable. Conforme se establece en el artículo 14, inciso 3 de la Ley Nº 25.326, el titular de los Datos Personales, previa acreditación de su identidad, tiene la facultad de ejercer el derecho de acceso a los mismos, dentro de los 10 (diez) días corridos desde la acreditación de su identidad, en forma gratuita a intervalos no inferiores a seis meses, salvo que se acredite un interés legítimo al efecto. Para la rectificación y supresión de los datos personales, el titular de los mismo podrá ejercer tales derechos dentro de los 5 (cinco) días hábiles de acreditada su identidad.
          Los Usuarios garantizan y responden, en cualquier caso, de la veracidad, exactitud, vigencia y autenticidad de los Datos Personales facilitados, y se comprometen a mantenerlos debidamente actualizados.
          Una vez registrado en Sharyco, el Usuario podrá revisar y cambiar la información que ha enviado durante el proceso de registración incluyendo:
          El nombre del usuario y dirección de correo electrónico. Sin perjuicio de los cambios que realice, Sharyco conservará los Datos Personales anteriores por motivos de seguridad y control del fraude.
          La información de la registración como:
          domicilio, ciudad, región, código postal, número principal de teléfono, número secundario de teléfono, número de fax, correo electrónico, etc.
          Numero de CUIT / CUIL
          La clave.

          En determinados casos, Sharyco mantendrá en sus archivos Datos Personales que el Usuario haya pedido que retire, con la finalidad de resolver disputas o reclamos, detectar problemas o incidencias y solucionarlos, y dar cumplimiento a lo dispuesto en los Términos y Condiciones Generales por un período de tiempo determinado por la ley. En cualquier caso, los Datos Personales de un Usuario no serán inmediatamente retirados de los archivos de Sharyco por motivos legales y técnicos, incluyendo sistemas de soportes de seguridad. Por tanto, no se debe esperar que todos los Datos Personales sean definitivamente borrados de las bases de datos.

          El Usuario podrá ejercitar en cualquier momento sus derechos de acceso, rectificación, cancelación y oposición al tratamiento de sus Datos Personales, mediante solicitud realizada en la siguiente dirección de e-mail: info@sharyco.com.

          <br /><br />
          <strong>8. CONFIDENCIALIDAD DE LA INFORMACIÓN</strong>
          <br /><br />
          Una vez registrado en su Sitio, Sharyco no venderá, alquilará o compartirá los Datos Personales excepto en las formas establecidas en estas políticas. Sin perjuicio de ello, el Usuario consiente en forma expresa que Sharyco transfiera total o parcialmente los Datos Personales a cualquiera de las sociedades controladas, controlantes y/o vinculadas con Sharyco, a cualquier título y en el momento, forma y condiciones que estime pertinentes. Sharyco hará todo lo que esté a su alcance para proteger la privacidad de la información. Puede suceder que en virtud de órdenes judiciales, o de regulaciones legales, Sharyco se vea compelido a revelar información a las autoridades o terceras partes bajo ciertas circunstancias, o bien en casos que terceras partes puedan interceptar o acceder a cierta información o transmisiones de datos en cuyo caso Sharyco no responderá por la información que sea revelada.

          <br /><br /><br /><br />
        </div>

      </LayoutResponsiveWSmall>
    )
  }
}

export default withTranslation()(InfoPrivacy);
