import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ToastContainer, notify } from '../../libraries/notifications';
import { history } from '../../routes';
import config from '../../config';
import { getObjectWithJsonDataToFormValues, capitalize, } from '../../libraries/utils';

import LayoutSmall from '../../components/layout/LayoutSmall';
import Button from '../../components/commons/Button';
import IconPoints from '../../components/customs/IconPoints';
import IconCoins from '../../components/customs/IconCoins';
import actionsAction from '../../context/actions/actions';
import transactionsAction from '../../context/transactions/actions';
import { addHours, isAfter, differenceInHours, differenceInMinutes, differenceInSeconds } from 'date-fns'

import MultiLineTextDisplay from '../../components/commons/MultiLineTextDisplay';

import Icon from '../../libraries/icons';
import LayoutResponsiveWSmall from '../../components/layout/LayoutResponsiveWSmall';

class Action extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {    
      action:null ,
      from:"",
      actionAvailable: false,
    }
  }
  
  componentDidMount() {
    console.log('ACCION');
    const params = this.props.match.params;
    console.log('PARAMS', params);
    if (this.props.location.state?.fromPath) {
      this.setState({ from: this.props.location.state.fromPath })
    }
    console.log("LOCATION",this.props.location);

    if (params.id){
      this.getData(params)
    } else {
      this.goBack();
    }

  }

  goBack = () => {
    const filters = this.props.location.state?.filters
    history.push({pathname: this.state.from || config.ROUTES.HOME_OPTIONS.ACTIONS, state: { filters}});
  }

  getData = async (params) => {
    this.setState({loading: true})
    await this.getAction(params);
    await this.getActionsTransactions()
    this.setState({loading: false})
  }

  getAction = async (params)=> {
    await this.props.onGetAction(params);
    const { action } = this.props;
    if (action.error) { 
      notify(this.t(action.error.message));
    } else {
      const actionData = getObjectWithJsonDataToFormValues(
        action.item,
        ['id', 'img', 'name', 'details', 'points', 'coins', 'rule', 'url', 'video','sub_type']
      );
      if(!actionData?.sub_type){
        actionData.sub_type = config.ACTIONS_SUB_TYPES.Normal
      }
      const actionOds = action.item.json_data.ods?.split(',');
      let odsIcons = [];
      actionOds.forEach( o => {
        if (config.ODS.find( e => e.code === o.trim() )) {
          odsIcons.push(config.ODS.find( e => e.code === o.trim() ).squareIcon)
        }
      });
      let everyHour = null
      if(actionData.rule > 24){
        everyHour = Math.floor(actionData.rule/24)
        if(everyHour < 2){
          everyHour = `${everyHour} día`
        } else {
          everyHour = `${everyHour} días`
        }
      } else {
        everyHour = `${actionData.rule} horas`
      }

        this.setState({ action: actionData, everyHour, odsIcons})
      }
    }

  check = (trx, rule) => {
    console.log("trx", trx)
    console.log("rule", rule)
    // TODO arreglar hora del servidor local
    const createdTime = /*process.env.NODE_ENV === "development" ? convertUTCDateToLocalDate(trx.created_at) :*/ new Date(trx.created_at)
    rule = parseInt(rule)
    let today = new Date()
    let availableDate = addHours(createdTime, rule)
    
    if(isAfter(availableDate, today)){
      let hoursLeft = differenceInHours(availableDate, today)
      if(hoursLeft > 24){
        hoursLeft = Math.floor(hoursLeft/24)
        if(hoursLeft < 2){
          hoursLeft = `${hoursLeft} día`
        } else {
          hoursLeft = `${hoursLeft} días`
        }
      } else {
        if(hoursLeft >= 2){
          hoursLeft = `${hoursLeft} horas`
        }
        else{
          if(hoursLeft < 1){
            const minutesLeft = differenceInMinutes(availableDate,today)
            if (minutesLeft >= 2) {
              hoursLeft = `${minutesLeft} minutos`
            }
            else {
              if (minutesLeft < 1) {
                const secondsLeft = differenceInSeconds(availableDate,today)
                hoursLeft = `${secondsLeft} segundos`
              }
              else {
                hoursLeft = `${minutesLeft} minuto`
              }
            }
          }
          else {
            hoursLeft = `${hoursLeft} hora`
          }
        }
      }
      this.setState({actionAvailable: false, hoursLeft})
    } else {
      this.setState({actionAvailable: true})    
    }
  }

  getActionsTransactions = async () => {
    let {action} = this.state
    let params = {target: action.id, type: config.TRANSACTIONS.ACTION.TYPE, sub_type: action.type, status: config.TRANSACTIONS.ACTION.STATUS.COMPLETED, created_by: this.props.auth.user.id}
    await this.props.onGetTransactions(params)
    let {transactions} = this.props
    if (transactions.error){
      notify(this.t(transactions.error.message));
      return false
    } else {
      if(action?.sub_type && action.sub_type =='question'){
        this.setState({actionAvailable: true})
      }else{

        if(transactions.items.length){
          this.check(transactions.items[0], action.rule)
        } else {
          this.setState({actionAvailable: true})
        }
      }
    }
  }

  render() {
    const { action, odsIcons, ods, from } = this.state;

    return (
     
      <LayoutResponsiveWSmall
        main={{ className: ""}}
        header={{ 
          // className: "bg-gradient-to-b from-primary-focus to-transparent bg-transparent fixed text-primary z-10",
          /* className: "bg-transparent fixed text-primary flex justify-between p-3", */
          left: { icon: 'arrow_left', action: this.goBack, className:'h-6 w-6 z-20'},
        }}
        container={{ className: "px-0"}}
        loading={this.state.loading}
      > 
        <ToastContainer/>
        {action && (
          <div>
            <div className="bg-white shadow flex justify-center">
              <img src={`${action.img}?v=1`} alt="" width="" className='w-2/3' />
            </div>
            <section className="p-4 pb-2 bg-white">
              <div className="flex items-center justify-center">
                <h2 className="font-medium">{action.name}</h2>
              </div>
              <div className="flex mt-2 gap-2 justify-center">
                <IconCoins iconClassName="h-4" color='green' className="" coins={action.coins}/>
                <IconPoints iconClassName="h-4" className="bg-success bg-opacity-10 flex gap-2 rounded" color='green' points={action.points}/>
              </div>
              <div className="flex items-center gap-3 border-b border-gray-200 pb-4 mt-4 mb-2 lg:w-1/2 lg:mx-auto lg:justify-center">
                <Button 
                  className="btn btn-primary flex-1" 
                  title={capitalize(this.t((!action?.sub_type||action.sub_type === config.ACTIONS_SUB_TYPES.Normal)?'confirmar acción':'Mirar Video')).toUpperCase()}
                  disabled={!this.state.actionAvailable}
                  onClick={() => history.push({
                    pathname: config.ROUTES.ACTIONS.SHARE.replace(':id', action.id ),
                    state: { from: config.ROUTES.ACTIONS.DETAIL.replace(':id', action.id ), prevFrom: from }
                  })}
                />
              </div>
              {!this.state.actionAvailable &&(!action.sub_type||action.sub_type === config.ACTIONS_SUB_TYPES.Normal)&& <small className='w-full text-center block text-neutral'>Esta acción estará disponible nuevamente en {this.state.hoursLeft}</small>}
              {this.state.actionAvailable &&(!action.sub_type||action.sub_type === config.ACTIONS_SUB_TYPES.Normal)&& (!action.sub_type || action.sub_type !== 'question') && <small className='w-full text-center block text-neutral'>Esta acción solo está disponible cada {this.state.everyHour}</small>}
            </section>
            <section className="container-fluid p-4 bg-base-200" >
              <div className='card shadow-md p-2 bg-success bg-opacity-50 mb-4'>
                <h4 className='text-base mb-2 mx-auto'>Esta acción contribuye a estos ODS:</h4>
                <div className='flex flex-wrap justify-center items-center mb-2'>
                  {odsIcons && odsIcons.map((o, i) => (
                    <img key={i} src={o} alt='ods' className='p-1 w-1/5 h-1/6 lg:w-20 lg:h-24' />
                  ))}
                </div>
                  <p className='border-t justify-center gap-2 border-success border-opacity-25 text-xs pt-2 flex items-center'
                    onClick={()=>history.push({pathname: config.ROUTES.ACTIONS.ODS, state: { from: config.ROUTES.ACTIONS.DETAIL.replace(':id', action.id ) }})}
                  >
                    <Icon name='information_solid' size={16} /> Aprendé más sobre las ODS
                  </p>
              </div>
              {/*<p className="text-neutral mt-5 mb-1">{action.details}</p>*/}
              <MultiLineTextDisplay className="" text={action.details}/>

              {action.url && (!action.sub_type||action.sub_type === config.ACTIONS_SUB_TYPES.Normal) &&
                <>
                  <h4 className="">{this.t("Enlaces externos")}</h4>
                  <a className="link text-primary" target="_blank" href={action.url}> {action.url}</a>
                </>}

              {action.video && (!action.sub_type||action.sub_type === config.ACTIONS_SUB_TYPES.Normal) &&
                <>
                <h4 className="">{this.t("Video")}</h4>
                <a className="link text-primary" target="_blank" href={action.video}> {action.video}</a>
                </>}
            </section>
          </div>
        )}

      </LayoutResponsiveWSmall> 
    ) 
  }
}


const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    action: state.actions.current,
    transactions: state.transactions.list
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAction : (params) => dispatch(actionsAction.get(params)),
    onGetTransactions : (params) => dispatch(transactionsAction.getAll(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Action));

