import useResponsive from '../../libraries/hooks/useResponsive'
import LayoutWithSidebar from './LayoutWithSidebar'
import LayoutDesktop from './LayoutDesktop'
import BottomNav from '../commons/BottomNav';

export default function LayoutResponsive({ children, header, right, left, logo, loading, container }) {
    const isMobile = useResponsive();

    return isMobile ? (
        <LayoutWithSidebar
            header={{
                header,
                logo: {},
                
            }}
        >
            {children}
            <BottomNav />
        </LayoutWithSidebar>
    ) : (
        <LayoutDesktop header={header}  >
            {children}
        </LayoutDesktop>
    )
}
