import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ToastContainer, notify } from '../libraries/notifications';
import { history } from '../routes';
import config from '../config';
import { isEmptyObject, getOwner } from '../libraries/utils';

import LayoutSmall from '../components/layout/LayoutSmall';
import Icon from '../libraries/icons';
import MessagesActions from '../context/messages/actions';

import transactionsAction from "../context/transactions/actions";
import { Form, Field } from 'react-final-form';
import FormLayout from '../components/forms/FormLayout';
import TextWithIconButtonInput from '../components/forms/TextWithIconButtonInput';
import Picker from 'emoji-picker-react';
import CommentElement from '../components/customs/CommentElement';
import FeedCard from '../components/customs/FeedCard';
import Loader from '../components/commons/Loader';
import LayoutResponsiveWSmall from '../components/layout/LayoutResponsiveWSmall';

class FeedComment extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {   
      comments : [],
      pickerOpen: false,
      owner: getOwner(this.props.auth.user),
      submitting: false,
      loading: false,
    }
  }

  componentDidMount() {
    const params = this.props.match.params;
    console.log('ACTION', params);
    if (params.id){
      this.getData(params.id)
    } else {
      this.goBack();
    }    
    if (!isEmptyObject(this.props.location.state)) { 
      const { from } = this.props.location.state;
      this.setState({ from });   
      console.log('FROM', from);
    }    
  }
  
  getData = async (id) => {
    this.setState({loading: true})
    await this.getComments(id)
    await this.getTransaction(id)
    this.setState({loading: false})
  }
  
  async getTransaction(id){
    await this.props.onGetTransaction({id})
    const {transaction} = this.props
    if(!transaction.item){
      await this.goBack()
    }
    if( transaction.error) {
      notify(transaction.error.message)
    } else {
      this.setState({transaction: transaction.item, transactionId:transaction.item.id})
      console.log('TRANSACTION', transaction)
    }
  }

  async getComments(id){
    const params = {}
    params.order_direction = 'ASC'

    const custom = {
      type: config.MESSAGES.TYPE.COMMENTS,
      related_to: id,
      comparison: 'AND',
      parent: { 
        comparison: '=',
        value: 'root', 
      }
    }

    params.where = {custom}
    await this.props.onGetAllComments(params);
    const { messages } = this.props;
    if (messages.error) {
      notify(this.t(messages.error.message));
    } else {
      this.setState({ comments: messages.items})
    }  
  }

  sendComment = async (values, form) => {
    if (!values.body || values.body.trim() === '') {
      return;
    }
    const {owner} = this.state
    const post = this.props.transaction.item
    this.setState({submitting: true, pickerOpen: false})
    const params = this.props.match.params;
    let target
    if(post?.json_data?.user){
      target = post?.json_data?.user?.id || JSON.parse(post?.json_data?.user).id
    }
    else{
      target = post?.json_data?.borrower?.id || JSON.parse(post?.json_data?.borrower).id
    }
    const obj = {
      ...values,
      source: owner,
      subject: "comment",
      target: target,
      related_to: params.id,
      parent: 'root',
      type: config.MESSAGES.TYPE.COMMENTS
    }
    await this.props.postComments(obj)
    const {message} = this.props
    if (message.error) {
      notify(message.error.message)
    } else {
      const comment = {...message.item, source: this.props.auth.user}
      this.setState({comments: [...this.state.comments, comment]})
    }

    let comments = post.json_data?.comments ? post.json_data?.comments : 0;
    const trxParams = {
      id: post.id,
      date : new Date(),
      json_data: {
        comments: +comments + 1
      }
    }
    await this.props.modifyTransaction(trxParams)
    const {transaction} = this.props
    if(transaction.error) {
      notify(transaction.error.message)
    } else {
      this.getData(post.id)
      if(owner !== target){
        const obj = {
          body: " comentó tu publicación",
          json_data: {
            post: post.id,
            redirect_notification_route: config.ROUTES.FEED.DETAIL.replace(':id', post.id),
          },
          source: owner,
          target: target,
          type: config.MESSAGES.TYPE.NOTIFICATION,
        }
        await this.props.postComments(obj);
        const { message } = this.props;
        if (message.error) {
          notify(this.t(message.error.message));
        } 
      }
    }
      
    this.setState({submitting: false})
    form.reset()
  }

  focusInput() {
    Array.from(document.getElementsByName('body'))[0].focus()
  }
  
  goBack = () => {
    history.push({ pathname: this.state.from || config.ROUTES.HOME, state: { from: config.ROUTES.MAIN.FEED }});
  }
 
  async onLike(id) {
    const owner = this.props.auth.user.id
    await this.props.onGetTransaction({id})
    const post = this.props.transaction.item
    let dislike = false;
    if(this.props.transaction.error) {
      notify(this.props.transaction.error.message)
    } else {
      let likes = post.json_data?.likes?.length ? post.json_data?.likes?.split(',') : []
      if(likes.includes(owner)) {
        dislike = true;
        post.json_data.likes = likes.filter(e => e !== owner && e.length).toString()
        await this.props.modifyTransaction(post)
      } else {
        const obj = {
          id: post.id,
          json_data: {
            likes: [...likes, owner].toString()
          }
        }
        await this.props.modifyTransaction(obj)
      }
      
      const {transaction} = this.props
      if(transaction.error) {
        notify(transaction.error.message)
      } else {
        this.getData(id)

        let target = transaction?.item?.json_data?.borrower ? 
        transaction?.item?.json_data?.borrower?.id || JSON.parse(transaction?.item?.json_data?.borrower).id : 
        transaction?.item?.json_data?.user?.id || JSON.parse(transaction?.item?.json_data?.user).id;
        
        if(!dislike && target !== owner){
          const obj = {
            body: " le dió like a tu publicación",
            json_data: {
              post: post.id,
              redirect_notification_route: config.ROUTES.FEED.DETAIL.replace(':id', post.id),
            },
            parent: " ",
            source: owner,
            subject: "like",
            target,
            type: config.MESSAGES.TYPE.NOTIFICATION,
          }
          await this.props.postComments(obj);
          const { message } = this.props;
          if (message.error) {
            notify(this.t(message.error.message));
          }
        }
      }
    }
    
  }

  render() {
    const {pickerOpen, comments, submitting, transaction } = this.state
    //const {json_data} = {...transaction}
    //const {user, action_img, description} = {...json_data}
    //const {profile_image, username, first_name, last_name} = {...user}

    return (
      <LayoutResponsiveWSmall
        main={{ className: ""}}
        header={{ 
          className: "bg-white text-primary flex justify-between p-3",
          left: { icon: 'arrow_left', action: this.goBack },
          title: this.state.transaction?.json_data?.action?.name
        }}
        container={{ className: "px-0"}}
      > 
        <ToastContainer/>
        <div className='flex flex-col h-full w-full'>
          {this.state.loading && (<Loader size="full"/>)}
          {transaction && (
            <FeedCard
              className="w-full bg-white"
              route={""}
              data={transaction}
              owner={this.props.auth.user.id}
              onClickLikeIt={() => this.onLike(transaction.id)}
              allowComment={false}
            />
          )}        
          <div className="bg-base-200 h-full">
            <section className="container-fluid p-3 flex-grow ">
              {!this.state.loading && comments.map(comment => {
                return <CommentElement key={comment.id} data={comment}/>
              })}
            </section>
          </div>
          <Form
            initialValues={{}}
            onSubmit={this.sendComment}
            mutators={{
              insertEmoji: (args, state, utils) => {
                utils.changeValue(state, 'body', () => (args[1] || '') + args[0].emoji)
              },
            }}
          >
            {({ handleSubmit, form, values }) => {
              return (
                <FormLayout form={form} onSubmit={handleSubmit}>
                  {<div className='w-full mb-2'>
                    <div className='input flex items-center m-1'>
                      <div className='border-r pr-2'>
                        <button type='button' className={` flex`}>
                          <Icon className="h-4 w-4" name={pickerOpen ? 'close': "emoji_face"} 
                            onClick={()=> {
                              if(submitting) return
                              this.setState({pickerOpen:!pickerOpen})
                              this.focusInput()
                              }}
                          />
                        </button>
                      </div>
                      <Field
                        name="body"
                        component={TextWithIconButtonInput}
                        placeholder={this.t('Comment')}
                        readOnly={submitting}
                        submitting={submitting}
                        icon={'paper_airplane'}
                        inputClassName={'pl-2 ml-2'}
                      />
                    </div>
                    <div className={!pickerOpen ? 'hidden' : null}>
                      <Picker 
                        className='w-full'
                        searchPlaceholder='Buscar emojis'
                        pickerStyle={{ width: '100%', height: '40vh' }}
                        onEmojiClick={(e, emoji)=> {
                          form.mutators.insertEmoji(emoji, values.body)
                          this.focusInput()
                        }}
                        disableAutoFocus
                      />
                    </div>
                  </div>}
                </FormLayout>
              );
            }}
          </Form>          
        </div>
      </LayoutResponsiveWSmall>
    ) 
  }
}


const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    messages: state.messages.list,
    message: state.messages.current,
    transaction: state.transactions.current,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAllComments: (params) => dispatch(MessagesActions.getAll(params)),
    postComments: (params) => dispatch(MessagesActions.saveOrUpdate(params)),
    onGetTransaction: (params) => dispatch(transactionsAction.get(params)),
    modifyTransaction: (params) => dispatch(transactionsAction.saveOrUpdate(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(FeedComment));
