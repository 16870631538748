import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import config from '../../config';
import Swal from 'sweetalert2';

import Sidebar from '../../components/layout/Sidebar';
import ActivityCard from '../../components/customs/ActivityCard';
import ActivityBenefitCard from '../../components/customs/ActivityBenefitCard';
import BottomNav from '../../components/commons/BottomNav';
import Loader from '../../components/commons/Loader';
import Icon from '../../libraries/icons';

import { ToastContainer, notify } from '../../libraries/notifications';

import transactionsAction from '../../context/transactions/actions';
import { zonedTimeToUtc } from 'date-fns-tz'

import { history } from '../../routes';
import LayoutResponsive from '../../components/layout/LayoutResponsive';

class Activities extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.from = this.props.location.state?.from || { pathname: '/' };
    this.state = {
    activities : []
    }
  }
  
  componentDidMount() {
    this.getActivities();
  }

  getActivities = async ()=> {
    this.setState({loading: true})
    const user = this.props.location.state?.user || this.props.auth.user;
    const ownerParams = {
      type: [
        config.TRANSACTIONS.INITIATIVE.TYPE,
        config.TRANSACTIONS.ACTION.TYPE,
        config.TRANSACTIONS.BORROW.TYPE,
        config.TRANSACTIONS.BENEFIT.TYPE,
      ],
      status: [
        "attended",
        "pending",
        "postulated",
        "completed",
        "archived",
        // "accepted",
        "on_user",
        "on_owner",
        "review",
        "generated",
        "consumed",
      ],
      created_by: user.id
    }
    await this.props.onGetOwnerActivities(ownerParams);
    const { activities } = this.props;
    let ownerActivities = activities.items

    if (activities.error) { 
      notify(this.t(activities.error.message));
    } else {
      const sourceParams = {
        type:config.TRANSACTIONS.BORROW.TYPE,
        source: user.id,
        status: [
          "accepted",
          "on_user",
          "on_owner",
          "review",
          "completed"
        ],
        owner: user.unique_id,
      }
      await this.props.onGetSourceActivities(sourceParams);
      const { activities } = this.props;
      let sourceActivities = activities.items
      if (activities.error) { 
        notify(this.t(activities.error.message));
      } else {
        let totalActivities = ownerActivities.concat(sourceActivities);
        totalActivities.sort((a, b) => 
           new Date(b.created_at) - new Date(a.created_at)
        );
        console.log("TOTAL ACTIVITIES", totalActivities);
        this.setState({ activities: totalActivities });
      }
    }
    this.setState({ loading: false , user})
  }

  deleteAction = async (id,created_by) => {
    const idUser= this.props.auth.user.id
    // validar que el usuario pueda eliminar solo sus acciones
    if(created_by !== idUser) return notify("No se pudo eliminar la accion")
    console.log("id", id)
    const swalWithStyle = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary mr-2',
        cancelButton: 'btn btn-ghost',
      },
      buttonsStyling: false
    })
     const result = await swalWithStyle.fire({
        title: this.t("¿Estás seguro que deseas eliminar la actividad?"),
        // text: "eliminar la actividad",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.t("Eliminar"),
        cancelButtonText: this.t("Cancelar"),
      })
      .then((result) => {
        return result
      });
    console.log('ANSWER', result);

    if (result && result.isConfirmed) {
      console.log("yes")
      await this.props.onDeleteActivity({id});
      const { activity } = this.props;
      if (activity.error) { 
        notify(this.t(activity.error.message));
      } else {
        this.getActivities()
      }
    }

  }
 
  gotoProduct = (p) => {
    let route = config.ROUTES.PRODUCTS.DETAIL
    let product_id = p.json_data.product ? p.json_data.product.id : p.json_data.offer.id
    if(p.sub_type === 'favor') route = config.ROUTES.FAVORS.DETAIL
    if(p.sub_type === 'service') route = config.ROUTES.SERVICES.DETAIL
    history.push({ pathname: route.replace(':id', product_id), state: { fromPath: config.ROUTES.USER.ACTIVITIES }})
  }
 
  render() {
    const { activities, user } = this.state;

    return (
      <LayoutResponsive
      main={{ className: "pb-48" }}
      header={{
        logo: true,
        //content: <NavButtonBar options={buttonBarUpper} active={topNav} onClick={this.onNavBarClick} />
      }}
      >
        {/* <Sidebar 
          header={{ 
            title: this.props.location.state?.user
              ? this.t("Actividad de " + this.props.location.state.user.json_data.first_name + " " + this.props.location.state.user.json_data.last_name)
              : this.t("Mi actividad"),
            className: '',
            // right: {
            //   action: () => {
            //     if(!this.props.location.state) history.push(config.ROUTES.HOME)
            //     else if (this.props.location.state?.user) {
            //       history.push(config.ROUTES.USER.PUBLIC_PROFILE.replace(":id", this.props.location.state.user.id))
            //     } else { 
            //       history.push(config.ROUTES.USER.PROFILE)
            //     }
            //   },
            //   icon: 'arrow_left'
            // }
          }}
        /> */}
        <ToastContainer/>
        <section className="p-4 mx-auto">
        { this.props.activities.loading && (<Loader/>) }
            {!this.props.activities.loading && activities.length === 0 && (
              <div className="text-center p-10 text-gray-400">
                <Icon className="h-14 w-14 mx-auto mb-2" name="loans" />
                <p className="mb-1">{this.t("No se registran actividades")}</p>
              </div>
            )}
          <div className="container bg-white shadow">
          {
            activities.map((activity,index)=> {
              let actions = activity.type === config.TRANSACTIONS.ACTION.TYPE ? [{onClick: () => this.deleteAction(activity.id,activity.created_by), title: "Deshacer", disabled: this.state.loading}] : null
              if(activity.type !== config.TRANSACTIONS.BENEFIT.TYPE){
                return(
                  <ActivityCard
                  key={index}
                  /* time = {this.t("timeRemains", { date: new Date(zonedTimeToUtc(activity.created_at, timeZone)) })} */
                  activity={activity}
                  user ={user?.id}
                  statuses={config.STATUS.TRANSACTIONS}
                  types = {config.TRANSACTIONS.TYPES}
                  subtypes = {config.TRANSACTIONS.INITIATIVE.SUB_TYPES}
                  actions={this.props.location.state?.user ? [] : actions}
                  onClick = {() => {
                      if(activity.type === 'initiative'){
                        if(activity.sub_type === 'donations'){
                          history.push(config.ROUTES.DONATIONS.DETAIL.replace(":id", activity.json_data.initiative.id))
                        }  
                        if(activity.sub_type === 'recycling'){
                          history.push(config.ROUTES.RECYCLING.DETAIL.replace(":id", activity.json_data.initiative.id))
                        }  
                        if(activity.sub_type === 'volunteering'){
                          history.push(config.ROUTES.VOLUNTEERING.DETAIL.replace(":id", activity.json_data.initiative.id))
                        }  
                      }
                      if(activity.type === 'borrow'){
                        this.gotoProduct(activity)
                      }
                    }}
                />
                )
              } else {
                 return(
                  <ActivityBenefitCard
                    key={index}
                    /* time = {this.t("timeRemains", { date: new Date(zonedTimeToUtc(activity.created_at, timeZone)) })} */
                    activity={activity}
                    statuses={config.STATUS.TRANSACTIONS}
                    types = {config.TRANSACTIONS.TYPES}
                    subtypes = {config.TRANSACTIONS.INITIATIVE.SUB_TYPES}
                    onClick = {() => {}}    
                  />
                )
              }
            })
          }
          </div>
        </section>
        {/* <BottomNav/> */}
      </LayoutResponsive>
    ) 
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    activities: state.transactions.list,
    activity: state.transactions.current,
    products: state.products.list,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetOwnerActivities: (ownerParams) => dispatch(transactionsAction.getAll(ownerParams)),
    onGetSourceActivities: (sourceParams) => dispatch(transactionsAction.getAll(sourceParams)),
    onDeleteActivity: (params) => dispatch(transactionsAction.del(params)),
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(Activities)) ;


