import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import userActions from '../../context/users/actions';
import { ToastContainer, notify } from '../../libraries/notifications';

import { history } from '../../routes';
import config from '../../config';
import LayoutSmall from '../../components/layout/LayoutSmall';
import UserProfileImage from '../../components/commons/UserProfileImage';
import IconPointsCircle from '../../components/customs/IconPointsCircle';
import IconCoinsCircle from '../../components/customs/IconCoinsCircle';

import levelsAction from '../../context/levels/actions';
import companiesActions from '../../context/companies/actions';

import StarRating from "react-svg-star-rating";

import { capitalizePhrase } from '../../libraries/utils';
import Loader from '../../components/commons/Loader';
import LayoutResponsiveWSmall from '../../components/layout/LayoutResponsiveWSmall';

class UserReferrals extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      activities : [],
      userId: this.props.auth.user.id,
    }
  }

  componentDidMount() {
    console.log('REFERRALS PAGE', this.props.auth.user);
    const params = this.props.match.params;

    let userId = this.props.auth.user.id
    let isPublicProfile = false
    if (params.id) {
      userId = params.id
      isPublicProfile = true
    }

    let fromPath = null
    if(this.props.location?.state?.fromPath){
      fromPath = this.props.location.state.fromPath
    }

    this.setState({ userId, isPublicProfile, fromPath }, () => this.getAllData())
    
    if(!this.props.auth.user.ancestors?.company?.name){
      this.getCompany()
    }
  }


  getCompany = async () => {
    await this.props.onGetCompanies({owner: this.props.auth.user.unique_id});
    const { companies } = this.props
     if (companies.error) {
      notify(this.t(companies.error))
    } else {
      if(companies.items.length){{
        this.setState({company_name: companies.items[0]?.name})
      }}
    }
  }

  getAllData = async () => {
    const { userId: id } = this.state
    this.setState({dataLoading: true})
    
    let promises = [];
    console.log('USER ID', id);
    promises.push(this.getUser(id))
    promises.push(this.getRanking(id))
    promises.push(this.getLevels())
    promises.push(this.getRewards(id))
    promises.push(this.getInvites(id))
    promises.push(this.getReferentUsers(id))

    let d = await Promise.all(promises)
    let state = { dataLoading: false };
    d.forEach(x => {
      if(d.error){
        notify(this.t(x.error.message));
      } else {
        state = {...state, ...x}
      }
    })
    if(state.rewards?.total?.points === "0"){
      state.user_ranking = "N/A"
    }
    this.setState(state)
    await this.mapInvitesWithReferents()

  }

  getUser = async (id) => {
    await this.props.onGetUser({id});
    const { user } = this.props
     if (user.error) {
      return {error: this.t(user.error.message)}
    } else {
      const rate_total = user.item.json_data.rate_total
      const rate_count = user.item.json_data.rate_count
      const rating = rate_total && (rate_count !== 0) ? rate_total/rate_count : 0

      return {user: user.item, rating}
    }
  }

  getRanking = async (id) => {
    await this.props.onGetUsersRanking({ user_type: config.USER_TYPE.REGULAR, unique_id: this.props.auth.user.unique_id });
    const { ranking } = this.props
    if(ranking?.error){
      return {error: this.t("Error obteniendo el ranking")}
    } else {
      let user_ranking = "N/A"
      console.log("RANKING=============",ranking)
      if(ranking){
        ranking.forEach((r, index) => {
          if(r.id === id){
            user_ranking = index+1;
            this.setState({ prevRank: ranking[index-1]})
          }
        })
      }
      return {user_ranking}
    }
  }

  getInvites = async (id) => {
    await this.props.onGetInvites(id);
    const { invites } = this.props;
    if(invites?.error){
      return {error: this.t("Error obteniendo los usuarios referidos")}
    } else {
      const sortedInvites = invites.invites.sort((a,b) => { return a.points + b.points })
      console.log("INVITADOS",invites.invites)
      return { invites: sortedInvites }
    }
  }
  getReferentUsers = async (referentID) => {
    await this.props.onGetUsers({invited_by: referentID});
    this.setState({
      referentUsers: [...this.props.users.items],
    });
  }

  mapInvitesWithReferents = async () => {
    const {invites, referentUsers} = this.state
    const newInvites = referentUsers.map((user) => {
      let result = invites.find((invite => invite.user.id === user.id))
      if(!result) {
        result = {
          number_of_actions : 0,
          points: 0,
          user: user
        }
      }
      return result
    })
    this.setState({invites: newInvites})
  }
  
  getRewards = async (id) => {

    await this.props.onGetRewards({id});
    const { rewards } = this.props
    if (!rewards.total) {
      return {error: this.t("Error obteniendo las recompensas")};
    } else {
      console.log("REWARDS========", rewards.level)
      return {rewards, userLevel: rewards.level}
    }
  }

  getPointsLeftForNextLevel = () => {
    if(this.state.userLevel === config.MAX_LEVEL) return null
    console.log(this.state.userLevel);
    return this.state.levels[(this.state.userLevel+1).toString()].min - this.props.auth.user.rewards.total.points
  }
 
  getLevels = async () => {
    await this.props.onGetLevels();
    const { levels } = this.props;
    if (levels.error) {
      notify(this.t(levels.error.message));
    } else {
      console.log("LEVELS", levels.item)
      this.setState({ levels: levels.item })
    }  
  }

  goProfileRating = (opinions, type, isPublicProfile) => {
    const { userId } = this.state
    history.push({ 
      pathname: config.ROUTES.USER.PROFILE_RATINGS,
      state: { opinions, type, isPublicProfile, userId }
    })
  }

  goBack = () => {
    let path = this.state.fromPath ? this.state.fromPath : config.ROUTES.HOME
    history.push(path)
  }

  calculateInvitesTotal() {
    var total = 0;
    for (let { points } of this.state.invites) 
      total += points;
    return total;
  }


  render() {
    const { user, rewards, rating, userLevel, invites } = this.state;
    const company_name = this.state.company_name ? this.state.company_name : this.props.auth.user.ancestors?.company?.name

    return (
      <LayoutResponsiveWSmall
        main={{ className: ""}}
        header={{ 
          className: "bg-transparent text-primary-content absolute top-0 left-0 z-20",
          left: { icon: 'arrow_left', action: this.goBack },
        }}
        container={{ className: "px-0"}}
        loading={this.props.user.loading}
      >
        <ToastContainer/>
        {!user && <div className='h-48 grid place-content-center'><Loader spinnerClassName='h-16 w-16 text-primary' /></div>}
        {user && (
          <section className="text-center relative h-64 bg-primary-focus">
            <div className='bg-base-content absolute top-0 w-full left-0 h-full z-10 bg-opacity-60'></div>
            <img src={user?.profile_image} className='w-full h-full absolute top-0 mix-blend-soft-light blur-sm left-0 z-0 object-cover' />
            <div className="p-4 relative z-10">
              <UserProfileImage data={user} picture={{ onClick: () => history.push(config.ROUTES.USER.INFO) }}/>
              <div className="flex justify-center items-center gap-10 mx-auto">
                <div className="rounded-full text-white w-12 h-12 grid">
                  <IconCoinsCircle  className="sidebar--points" coins={rewards.total.coins}/>
                </div>
                <div>
                  <h3 className=' text-white'>{capitalizePhrase(user?.name)}</h3>
                  <p className='text-center text-white'>Nivel {userLevel && userLevel}</p>
                </div>
                <div className="rounded-full text-white w-12 h-12 grid">
                  <IconPointsCircle className="sidebar--points"  points={rewards.total.points} iconClassName="w-5" />
                </div> 
              </div>
              <div className='mx-auto mt-4 gap-2 items-center py-1 px-3 flex border border-white border-opacity-30 rounded-md w-min'>
                <p className='text-sm font-light text-white'>{rating.toFixed(1)}</p>  <StarRating containerClassName="flex" unit="float" initialRating={rating} size={14} isReadOnly={true}/>
              </div>
              
            </div>
            <div className='text-white relative z-20'>Empresa<p className='font-bold'>{company_name}</p></div>
          </section>
        )}
          <section>
          <div className='flex mt-5 flex-col px-4 gap-2'>
            {invites &&
              <>
                {invites.map((u,i)=>(
                  <div className={'card bg-white px-4 py-2 flex justify-between flex-row'}
                    onClick={()=>history.push({pathname: config.ROUTES.USER.PUBLIC_PROFILE.replace(':id',u.user.id), state: { fromPath: config.ROUTES.USER.REFERRALS}})}
                  >
                    <div className='flex items-center gap-3'>
                      <p className='text-gray-300 w-3'>{i+1}</p>
                      <UserProfileImage picture={{className:'h-6 w-6 !m-0'}} data={u.user} />
                      {/* <div className='h-5 w-5 rounded-full bg-primary' /> */}
                      <p className='text-primary'>{capitalizePhrase(u.user.name)}</p>
                    </div>
                    <div className='text-yellow-400 font-semibold text-sm flex items-center'>{u.points}pts</div>
                  </div>
                ))}
              <p className="mt-2 px-4 py-2 bg-white font-semibold text-right rounded-lg self-end">Total: <span className="text-yellow-500">{this.calculateInvitesTotal()}pts</span></p>
              </>
              } 
              {invites && invites.length < 1 &&
                <p className='text-center'>No tienes usuarios referidos</p>
              }
            </div>
            
          </section>
      </LayoutResponsiveWSmall>
    ) 
  }
}

const mapStateToProps = state => {
  return {
    user: state.users.current,
    users: state.users.list,
    auth: state.users.auth,
    rewards: state.users.auth.user.rewards,
    ranking: state.users.list.ranking,
    levels: state.levels.current,
    companies: state.companies.list,
    invites: state.users.invites,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetUser: (params) => dispatch(userActions.get(params)),
    onGetUsers: params => dispatch(userActions.getAll(params)),
    onGetUsersRanking: (params) => dispatch(userActions.getRanking(params)),
    onGetRewards: (params) => dispatch(userActions.getRewards(params)),
    onGetLevels: (params) => dispatch(levelsAction.get(params)),
    onGetCompanies: (params) => dispatch(companiesActions.get(params)),
    onGetInvites: (params) => dispatch(userActions.getInvites(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(UserReferrals));