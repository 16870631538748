import React, {useEffect, useState} from 'react'
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { history } from '../../routes';
import config from '../../config';
import logo from '../../assets/img/logo.png';
import medal from '../../assets/img/medal.png';
import Button from '../commons/Button';
import ButtonIcon from '../commons/ButtonIcon';
import MenuItem from '../commons/MenuItem';
import UserProfileImage from '../commons/UserProfileImage';
import IconPointsCircle from '../customs/IconPointsCircle';
import IconCoinsCircle from '../customs/IconCoinsCircle';
import { capitalizePhrase, capitalize } from '../../libraries/utils';
import userActions from '../../context/users/actions';
import prizesActions from '../../context/benefits/actions';
import settingsActions from '../../context/settings/actions'

export default function PointRanking() {
  const { t, i18n } = useTranslation();
  const rewards = useSelector(state => state.users.auth.user.rewards)
  const ranking = useSelector(state => state.users.list.ranking)
  // const user = useSelector(state => state.users.auth.user)
  const rankingTop = ranking?.slice(0, 8);


  return (
    <>
        <div className='flex flex-col'>
            <p className='text-xl font-bold'>Tus puntos acumulados</p>           
            <div className='bg-white w-56 mt-4 rounded-xl h-10 gap-2 flex'>
              <IconCoinsCircle iconClassName='w-8' className='flex justify-self-end text-accent flex-col justify-center'/>
              <span className='text-lg text-center font-bold flex flex-col justify-center'>{rewards?.total?.coins}</span>
              <p className='text-md text-center flex flex-col justify-center'>Puntos Shary</p>
            </div>
            <div className='bg-white w-56  mt-4 rounded-xl h-10 gap-2 flex'>
                <IconPointsCircle iconClassName='w-8' className='flex flex-col text-accent justify-center'/>
                <span className='font-bold flex flex-col justify-center text-lg text-center'>{rewards?.total?.points}</span>
                <p className='text-md flex flex-col justify-center text-center'>Pts Impacto</p>
            </div>
            <div>
            <p className='text-lg font-bold mt-5'>Ranking de tu empresa</p>           
              <div className='flex mt-5 flex-col gap-2 w-full'>
                {rankingTop &&
                    rankingTop.map((u,i)=>(
                      <div className={'card py-1 bg-white px-4 flex justify-between flex-row h-8'}
                        onClick={()=>history.push({pathname: config.ROUTES.USER.PUBLIC_PROFILE.replace(':id',u.id), state: { fromPath: config.ROUTES.USER.RANKING}})}
                      >
                        <div className='flex items-center gap-2'>
                          <p className='text-gray-300'>{i+1}</p>
                          <UserProfileImage picture={{className:'h-5 w-5 !m-0'}} data={u} />
                          <p className='text-primary'>{capitalizePhrase(u.name)}</p>
                        </div>
                        <div className='flex items-center text-yellow-400 text-center font-semibold text-sm'>{u.points}pts</div>
                      </div>
                  ))
                }
              </div> 
            </div>
        </div> 
    </>
  )
}
