import React, { memo } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import BottomNav from "../../components/commons/BottomNav";
import LayoutWithSidebar from "../../components/layout/LayoutWithSidebar";
import userActions from '../../context/users/actions';
import { ToastContainer, notify } from '../../libraries/notifications';
import { history } from '../../routes';
import config from '../../config';
import LayoutResponsive from "../../components/layout/LayoutResponsive";

class Directory extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      user: null,
      business: null,
      users : null,
      loading: true,
      searchQuery: '',
      // filteredList: null,
    };
    this.listOfRefs = []

  }

  async componentDidMount() {
    this.setState({loading: true})
    await this.getUser(this.props.auth.user.id);
    await this.getAllUsers();
    this.setState({business: this.props.auth.user?.ancestors?.company?.name})
    this.setState({loading: false})

  }

  getUser = async (id) => {
    //if(!this.props.user.item) await this.props.onGetUser({id});
    //if(this.props.user.error) return notify(this.props.user.error?.message)
    this.setState({user: {...this.props.auth.user}})
  }

  getAllUsers = async () => {
    await this.props.onGetAllUsers({unique_id: this.props.auth.user.unique_id, enabled: 1})
    const { error, items } = this.props.users;
    if(error) {
      return notify(this.props.users.error.message)
    }
    const users = this.sortUsers(items)
    this.setState({ users });
  }


  sortUsers = (users) => {
    return users
      .map(user => {
        if(user.name) user.name = user.name.trim().toLowerCase()
        if(!user.name) user.name = user.email
        return user
      })
      .sort((a, b) => {
        if (a.name > b.name) return 1
        if (a.name < b.name) return -1
        return 0
      })
  }

  // handleSearch = (event) => {
  //   const searchQuery = event.target.value;
  //   const { users } = this.state;

  //   const filteredList = users.filter((user) =>
  //     user.name.toLowerCase().includes(searchQuery.toLowerCase())
  //   );

  //   this.setState({
  //     searchQuery,
  //     filteredList,
  //   });
  // };

  render() {
    const { user: currentUser, users, business, loading } = this.state;
    return (
      <LayoutResponsive
        main={{ className: "pb-14"}}
        header={{ 
          // logo: true,
          title: <Test users={users?.length} business={business} />,
          // content: <Search value={searchQuery} onChange={this.handleSearch} /> // TODO: fix search
          // content: <Search />
        }}
        container={{ className: "px-4"}}
      >
        <ToastContainer />
        <section className="pb-10 p-3 w-full mx-auto">
          <div className="py-4 pt-0 relative flex h-full">
            <div className="relative flex-1 overflow-scroll no-scrollbar">
              { loading && Array(20).fill().map((_, index) =>{
                if(index === 0) return (
                  <div className="px-4 py-2 mb-3" key={index}>
                    <p className="h-6 text-lg font-semibold uppercase cursor-pointer">
                      A
                    </p>
                  </div>
                )
                return  <SkeletonCard key={index}/>
              }) }


              { !loading && users.map((user, i) => {
                if(user.id === currentUser.id) return null
                if(i === 0 || user.name[0] !== users[i-1].name[0]) return (
                  <div key={user.id}
                    id={user.name[0].toUpperCase()}
                    ref={ref => (this.listOfRefs[i] = ref)}
                  >
                    <div className="px-4 py-2 mb-3">
                      <p className="h-6 text-lg font-semibold uppercase cursor-pointer">
                        {user.name[0]}
                      </p>
                    </div>
                    <Card key={user.id} user={user}/>
                  </div>
                )
                return <Card key={user.id} user={user}/>
              })}
            </div>
            <div className="px-2 pr-0">
              <AlphabeticalScrollBar itemRefs={this.listOfRefs} />
            </div> 
          
          </div>

        </section>

        {/* <BottomNav/> */}
      </LayoutResponsive>
    )
  }
}

function Test({ business, users = 0 }) {
  if (users === 0) {
    return (
      <div className="flex flex-col ">
        <span className="h-3 my-1 w-16 bg-gray-200 rounded-md animate-pulse" />
        <span className="h-3 my-1 w-24 bg-gray-200 rounded-md animate-pulse" />
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      {business}
      <span className="text-xs text-gray-500 font-normal">
        {users > 1 ? 'colaboradores' : 'colaborador'}
      </span>
    </div>
  );
}
function Search ({value, onChange}) {
  return (
    <div className="flex flex-row items-center justify-between">
      <div className="w-full px-4 py-2 border-t flex items-center justify-between bg-white">
        <input className="w-full bg-gray-100 rounded-full py-2 px-4 text-gray-700 leading-tight focus:outline-none" id="search" type="text" placeholder="Search" 
          onChange={onChange}
          value={value}
        />
      </div>
    </div>
  )
}

function Card ({user}) {
  return (
    <div
      onClick={() => {
        history.push({
          pathname: config.ROUTES.USER.PUBLIC_PROFILE.replace(":id", user.id),
          state: {
            fromPath: config.ROUTES.USER.DIRECTORY
          }
        })
      }}
      className="px-4 py-2 mb-3 flex items-center gap-3 bg-white rounded-lg"
    >
      <div className="w-6 h-6 rounded-full bg-slate-200">
        {(user.profile_image || user.cover_image) && <img
          className="object-cover w-full h-full rounded-full"
          alt="Avatar"
          src={ user.profile_image || user.cover_image }
          loading="lazy"
          /> }
      </div>
        <h5 className="text-sm capitalize">{user.name}</h5>
    </div>
  )
}

function SkeletonCard () {
  return (
    <div className="px-4 py-2 mb-3 flex items-center gap-3 bg-white rounded-lg animate-pulse">
      <div className="w-6 h-6 rounded-full bg-slate-200"></div>
      <div className="w-44 h-4 rounded-full bg-slate-200"></div>
    </div>
  )
}

const AlphabeticalScrollBar = memo(({ itemRefs }) => {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("")
    
    const handleClick = ref => {
      ref.scrollIntoView({ behavior: "smooth", block: "start" })
    }

    return (
      <div className="w-8 h-full py-4 flex flex-col gap-2 overflow-scroll no-scrollbar">
        {alphabet.map(letter => {
          const ref = itemRefs.find(ref => ref?.id === letter) || null
          if (!ref) return null
          return (
            <div
              key={letter}
              className="w-full text-center text-xs text-gray-500 cursor-pointer hover:font-bold hover:scale-[2.0] transition-transform"
              onClick={() => {
                if (itemRefs && itemRefs.length > 0) {
                  handleClick(ref)
                }
              }}
            >
              {letter}
            </div>
          )
        })}
      </div>
    )
  },
  prevProps => prevProps.itemRefs.length === 0
)

const mapStateToProps = (state) => {
  return {
    user: state.users.current,
    users: state.users.list,
    auth: state.users.auth,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUser: (params) => dispatch(userActions.get(params)),
    onGetAllUsers: (params) => dispatch(userActions.getAll(params)),


  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Directory));


