import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

import { ToastContainer, notify } from '../../libraries/notifications';
import { history } from '../../routes';
import config from '../../config';
import { getRewardFromLevel, getObjectWithJsonDataToFormValues, validateBenefitsTransactionsArray, validateBenefitRules, getDateFirstPart, daysOfWeekText, daysOfWeekToString, getOwner, calculateBalanceFromTrx } from '../../libraries/utils';

import LayoutSmall from '../../components/layout/LayoutSmall';
import Button from '../../components/commons/Button';

import transactionsAction from "../../context/transactions/actions";
import internalsActions from '../../context/internals/actions';
import usersActions from '../../context/users/actions';
import benefitsActions from '../../context/benefits/actions';
import businessesActions from '../../context/businesses/actions';
import companiesActions from "../../context/companies/actions";


import MultiLineTextDisplay from '../../components/commons/MultiLineTextDisplay';
import actions from '../../context/settings/actions';
import LayoutResponsiveWSmall from '../../components/layout/LayoutResponsiveWSmall';

class Benefit extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      loading: false,
      unavailabilityReasons: [],
      creditBalance: 0,
    }
  }

  componentDidMount() {
    const params = this.props.match.params;

    if (params.id) {
      this.getData(params.id)
      const state = this.props.location.state;
      if (state?.fromPath) {
        this.setState({ fromPath: state.fromPath, userLevel: this.props.auth.user.rewards.level })
      }
    }
  }

  getSetting= async()=>{
   await this.props.onGetSetting({"code": "email_admin_sharyco","type": "sharyco_admin_settings"});
   const error= this.props.setting.error
   if(error){
    notify(this.t(error.message));
   }else{
    this.setState({setting:this.props.setting.items})
   }
  }

  goBack = () => {
    let back = this.state.fromPath ? this.state.fromPath : config.ROUTES.HOME
    let fromPath = this.state.benefit?.type === config.TYPES.PRIZE ? config.ROUTES.MAIN.PRIZES : config.ROUTES.MAIN.BENEFITS
    history.push({ pathname: back, state: { from: fromPath } });
  }

  getData = async (id) => {
    this.setState({ loading: true })
    await this.getCreditBalance()
    await this.getBenefit(id);
    if (this.state.benefit.type === config.TYPES.BENEFIT){
      history.push(history.location.pathname.replace('benefits', 'prizes'))
    }
    await this.getTransaction();
    await this.getSetting()
    this.setState({ loading: false })
  }

  getBenefitsSamePack = async (pack) => {
    await this.props.onGetBenefits({ pack });
    const { benefits } = this.props;
    if (benefits.error) {
      notify(this.t(benefits.error.message));
      return []
    } else {
      return benefits.items.map(b => b.id)
    }
  }

  checkTransactions = async (rules) => {
    let params = { status: [config.TRANSACTIONS.BENEFIT.STATUS.CONSUMED, config.TRANSACTIONS.BENEFIT.STATUS.GENERATED, config.TRANSACTIONS.BENEFIT.STATUS.RESERVED], target: this.state.benefit.id, type: config.TRANSACTIONS.BENEFIT.TYPE }
    if (this.state.benefit.type === config.TYPES.PRIZE && this.state.benefit.pack) {
      params.status = [config.TRANSACTIONS.BENEFIT.STATUS.CONSUMED, config.TRANSACTIONS.BENEFIT.STATUS.GENERATED, config.TRANSACTIONS.BENEFIT.STATUS.RESERVED]
      params.target = await this.getBenefitsSamePack(this.state.benefit.pack)
      // console.log("__getTransaction::", params)
    }
    console.log("__getTransaction::", params)
    await this.props.onGetTransaction(params)
    let { transactions } = this.props
    if (transactions.error) {
      notify(this.t(transactions.error.message));
      return [transactions.error.message]
    } else {
      let historial = [...transactions.items]
      let user_uses = transactions.items.filter(b => b.target === this.state.benefit.id).length
      if (this.state.benefit.type === config.TYPES.PRIZE) {
        user_uses = transactions.items.filter(b => b.target === this.state.benefit.id).filter(t => t.status === config.TRANSACTIONS.BENEFIT.STATUS.CONSUMED)
      }
      this.setState({ historial, user_uses })

      if (transactions.items.length) {
        let all_transactions = [...transactions.items]
        let my_transactions = []
        //if(this.state.benefit.pack){          
        //  my_transactions = transactions.items.filter(t => t.json_data.benefit.pack === this.state.benefit.pack)
        //} else {
        //}
        my_transactions = transactions.items.filter(t => t.created_by === this.props.auth.user.id)

        //"ya tiene un QR generado para otro premio del mismo grupo, deberá cancelarlo antes de poder sacar uno nuevo"
        ///"ya canjeo un premio dentro de este mismo grupo, no puede canjar un nuevo premio"

        if (this.state.benefit.type === config.TYPES.PRIZE) {
          let generated_transactions = transactions.items.filter(t => t.status === config.TRANSACTIONS.BENEFIT.STATUS.GENERATED)
          let gtv = validateBenefitsTransactionsArray(generated_transactions, my_transactions.filter(t => t.status === config.TRANSACTIONS.BENEFIT.STATUS.GENERATED), rules, this.state.benefit.pack, this.state.benefit.id)
          if (gtv.includes("Ya agotaste los usos disponibles de este premio o de este grupo de premios")) {
            gtv = ["Ya tiene un QR generado para otro premio del mismo grupo, deberá cancelarlo antes de poder sacar uno nuevo"]
          } else {
            gtv = []
          }
          let consumed_transactions = transactions.items.filter(t => t.status === config.TRANSACTIONS.BENEFIT.STATUS.CONSUMED)
          let ctv = validateBenefitsTransactionsArray(consumed_transactions, my_transactions.filter(t => t.status === config.TRANSACTIONS.BENEFIT.STATUS.CONSUMED), rules, this.state.benefit.pack, this.state.benefit.id)
          if (ctv.includes("Ya agotaste los usos disponibles de este premio o de este grupo de premios")) {
            ctv = ["Ya canjeo un premio dentro de este mismo grupo, no puede canjear un nuevo premio"]
          }

          let reserved_transactions = transactions.items.filter(t => t.status === config.TRANSACTIONS.BENEFIT.STATUS.RESERVED)
          let rtv = validateBenefitsTransactionsArray(reserved_transactions, my_transactions.filter(t => t.status === config.TRANSACTIONS.BENEFIT.STATUS.RESERVED), rules, this.state.benefit.pack, this.state.benefit.id)
          if (rtv.includes("Ya agotaste los usos disponibles de este premio o de este grupo de premios")) {
            rtv = ["Ya canjeo un premio dentro de este mismo grupo, no puede canjear un nuevo premio"]
          }

          return [...ctv, ...gtv, ...rtv]
        }

        return validateBenefitsTransactionsArray(all_transactions, my_transactions, rules, this.state.benefit.pack)
      }

      return []
    }
  }


  checkBenefit = async (benefit) => {
    console.log("_______________checkTransactions")
    if (!config.VALIDATE_BENEFTIS) {
      this.setState({ benefitAvailable: true })
      return
    }

    let reasons = []

    let transactionCheckArray = await this.checkTransactions(benefit.json_data.rules)
    let rulesCheckArray = validateBenefitRules(benefit)
    reasons = [...transactionCheckArray, ...rulesCheckArray]

    // //otros check
    // if (benefit.type === config.TYPES.PRIZE) {
    //   if (parseInt(benefit.json_data?.required_level) > parseInt(this.props.auth.user.rewards.level)) {
    //     reasons = [...reasons, `No tiene nivel suficiente. Nivel minimo requerido: ${benefit.json_data?.required_level}`]
    //   }
    // } else {
    //   if (parseInt(this.props.auth.user.rewards.level) === 0) {
    //     reasons = [...reasons, `No tiene nivel suficiente. Nivel minimo requerido: 1`]
    //   }
    // }

    if (!benefit.enabled) {
      console.log("bncheck", "_______________benefit no enabled check")
      reasons = [...reasons, `premio inactivo`]
    }
    console.log('REASONS >>>', reasons)
    if (!reasons.length) {
      this.setState({ benefitAvailable: true })
    } else {
      this.setState({ benefitAvailable: false, unavailabilityReasons: reasons })
    }
  }

  getBenefitUser = async (id) => {
    await this.props.onGetUser({ id });
    const { user } = this.props
    if (user.error) {
      notify(this.t(user.error.message));
    } else {
      const userData = getObjectWithJsonDataToFormValues(
        user.item,
        ['id', 'username', 'first_name', 'last_name', 'profile_image', 'email']
      );
      this.setState({ benefitUser: userData })
      console.log("benefitUser", userData)
    }
  }

  getBenefitBusiness = async (id) => {
    await this.props.onGetBusiness(id);
    const { business } = this.props;
    if (business.error) {
      notify(this.t(business.error.message));
    } else {
      if (business.item) {
        const benefitBusiness = getObjectWithJsonDataToFormValues(
          business.item,
          ['id', 'name', 'image', 'url', 'address', 'website']
        );
        this.setState({ benefitBusiness });
      }
      //await this.getInitiativeUser(business.item.owner)
    }
  }

  getBenefit = async (id) => {
    await this.props.onGetBenefit(id);
    const { benefit } = this.props;
    if (benefit.error) {
      notify(this.t(benefit.error.message));
    } else {
      const user_current_level_benefit = getRewardFromLevel(this.state.userLevel, benefit.item.json_data)

      this.setState({ benefit: benefit.item, prize: benefit.item.type === config.TYPES.PRIZE, user_current_level_benefit, group_uses: benefit.item.json_data.rules.group_uses });
      await this.checkBenefit(benefit.item)

      if (benefit.item.json_data.business && benefit.item?.type !== config.TYPES.PRIZE) {
        await this.getBenefitBusiness(benefit.item.json_data.business.id)
      }
      console.log("benefit", benefit)
    }
  }

  getTransaction = async () => {
    let params = { status: config.TRANSACTIONS.BENEFIT.STATUS.GENERATED, target: this.state.benefit.id, created_by: this.props.auth.user.id, type: config.TRANSACTIONS.BENEFIT.TYPE }
    console.log("getTransaction::", params)

    await this.props.onGetTransaction(params)
    const { transactions } = this.props

    if (transactions.error) {
      notify(this.t(transactions.error.message));
    } else {
      if (transactions.items.length) {
        this.setState({ transaction: transactions.items[0] })
      } else {
        this.setState({ transaction: null })
      }
    }
  }

  checkCode = async (code) => {
    //const params = {"json_data":[{"field":"code","value":code}]}
    //await this.props.onGetTransaction(params)
    //let {transactions} = this.props
    //if(transactions.items.length === 0) return true
    //return false
    return true
  }

  saveTransaction = async () => {
    this.setState({ loading: true })

    const userData = getObjectWithJsonDataToFormValues(
      this.props.auth.user,
      ['id', 'username', 'first_name', 'last_name', 'profile_image', 'email']
    );
    
    debugger
    //TODO: revisar si es necesario en este momento
    let benefitData = {
      target: this.state.benefit.id,
      type: config.TRANSACTIONS.BENEFIT.TYPE,
      sub_type: "maslow", //vacio si prize
      status: config.TRANSACTIONS.BENEFIT.STATUS.GENERATED,//generated/consumed
      source: this.props.auth.user.unique_id,
      json_data: {
        user: userData,
        benefit: this.state.benefit,
        credits: this.state.benefit.json_data.credits,
        //busines,
        owner: userData,
      },
      owner: this.props.auth.user.unique_id,
    }
    // console.log("DATA", data)
    const benefit = this.state.benefit
    const user = this.props.auth.user
   
    await this.props.onSaveTransaction(benefitData);
    const { transaction:{item:trxItem} } = this.props;

    console.log("=========== Enviando Email")
    // Enviar email al usuario
    let mailData = {
      template: "benefit_redemption2",
      locale: "es",
      to: trxItem.json_data.user.email,
      first_name: trxItem.json_data.user.first_name,
      last_name: trxItem.json_data.user.last_name,
      params: {
        subject: "Descargaste un beneficio",
        user_name: `${ trxItem.json_data.user.first_name} ${trxItem.json_data.user.last_name}`,
        benefit_name: `${trxItem.json_data.benefit.name}`,
        // line_3: ".",
        // line_4: ``,
        year: `2024`,
        // link_app: config.ROUTES.USER.LOANS,
        //has_button: false
      }
    }
    console.log("=========== Data", mailData)
    await this.props.onSendMail(mailData); 
    const benefictTrx = this.props.transaction;
    let creditData = {
      type: "credits",
      sub_type: "credits",
      source: user.id,
      target: "CONSUMED",

      owner: user.unique_id,
      status: "paid",
      untaxed_amount: benefit.json_data.credits,
      total: benefit.json_data.credits,
      currency: "ARS",
      json_data: {
        reason: `Premio de Beneficio: ${benefit.name}`,
        user: userData,
      },
      ref: benefictTrx.item?.id,
    }
    await this.props.onSaveTransaction(creditData);

    console.log("=========== Enviando Email A Sharyco")
    // Enviar email al usuario
    let mailDataAdmin = {
      template: "benefit_redemption_for_admin",
      locale: "es",
      to: this.state.setting[0].value,
      first_name: trxItem.json_data.user.first_name,
      last_name: trxItem.json_data.user.last_name,
      params: {
        subject: "Se canjeo un beneficio",
        user_name: `${ trxItem.json_data.user.first_name} ${trxItem.json_data.user.last_name}`,
        benefit_name:`${trxItem.json_data.benefit.name}`,
        benefit_id:trxItem.id
      }
    }
    console.log("=========== Data sharyco", mailDataAdmin)
    await this.props.onSendMail(mailDataAdmin); 

    const { transaction } = this.props
    if (transaction.error) {
      notify(this.t(transaction.error.message));
    } else {
         // await this.sendMailUser()
      this.setState({ loading: false })
      const state = {
        title: "Premio generado exitosamente",
        headline: "Te llegará un mail con el código para poder canjearlo",
        button: {
          text: "Continuar",
          route: {
            pathname: config.ROUTES.HOME,
            // state: {from: this.state.benefit?.type === config.TYPES.PRIZE ? config.ROUTES.MAIN.PRIZES : config.ROUTES.MAIN.BENEFITS}
          },
        },
      }
      history.push({
        pathname: config.ROUTES.VOLUNTEERING.SUCCESS.replace(':id', this.state.benefit.id),
        state
      })
    }
  }

getBenefitValuesArray = (benefit) => {
  if (!benefit?.json_data?.values) return []

  const percentage = benefit.json_data?.percentage
  return Object.keys(benefit.json_data?.values).map(k => {
    return { level: parseInt(k) + 1, value: `${benefit.json_data?.values[k].value}${percentage ? "%" : ""}` }
  })
}

cancelConsumption = async () => {
  const swalWithStyle = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-primary mr-2',
      cancelButton: 'btn btn-ghost',
    },
    buttonsStyling: false
  })
  const result = await swalWithStyle.fire({
    title: this.t("¿Are you sure?"),
    text: "",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: this.t("Yes"),
    cancelButtonText: this.t("Cancel"),
  })
    .then((result) => {
      return result
    });
  console.log('ANSWER', result);

  if (result && result.isConfirmed) {
    let data = {
      id: this.state.transaction.id,
      status: "canceled",//config.TRANSACTIONS.BENEFIT.STATUS.CONSUMED,
    }
    await this.props.onSaveTransaction(data);
    const { transaction } = this.props
    if (transaction.error) {
      notify(this.t(transaction.error.message));
    }
    this.getData(this.state.benefit.id)
  }
  //else if (result && result.isDismissed) {
  //  Swal.fire('', this.t("Se canceló la participacion"), 'error');
  //}

}

getCreditBalance = async (visibility) => {
  await this.props.onGetBalance({ unique_id: this.props.auth.user.unique_id, user_id: this.props.auth.user.id, output: "user_balance" });
  if (this.props.user.error) {
    return notify(this.t(this.props.user.error.message));
  }
  const { balance } = this.props.user;
  let creditBalance = 0;
  if (balance && balance.credits) {
    creditBalance = balance.credits.balance || 0
  }

  this.setState({ creditBalance })

}


render() {
  const { benefit, benefitBusiness, prize, creditBalance } = this.state;
  const benefitValues = this.getBenefitValuesArray(benefit)
  const benefitOrPrize = prize ? "Premio" : "Beneficio"

  return (
    <LayoutResponsiveWSmall
      main={{ className: "" }}
      header={{
        className: "bg-transparent fixed text-primary",
        left: { icon: 'arrow_left', action: this.goBack },
        /* right: { icon: 'heart'} */
      }}
      container={{ className: "px-0" }}
      loading={this.state.loading}
    >
      <ToastContainer />
      {benefit && (
        <>
          <div className="bg-white shadow-lg">
            <div className="max-w-md mx-auto">
              <div className="max-h-72 overflow-hidden flex items-center justify-center aspect-video">
                <img src={benefit && benefit.json_data.picture} className="max-h-26 mx-auto" alt="" />
              </div>
              <div className="container p-3">
                <h1 className="">{benefit.name}</h1>
                <div className="flex items-center gap-3 mt-2">
                  {prize && "Premio de tu empresa"}
                  <div className="flex items-center">
                    {benefitBusiness &&
                      <div className="h-8 w-8 p-1 rounded-full flex items-center justify-center mr-2 border border-gray-200">
                        <img className="" src={benefitBusiness.image} alt="" />
                      </div>
                    }
                    {benefitBusiness && `${benefitOrPrize} de ${benefitBusiness.name}`}
                  </div>
                  {this.state.user_current_level_benefit &&
                    <div className="badge badge-primary inline-block">{this.state.user_current_level_benefit}%</div>
                  }
                </div>

                <Button
                  disabled={this.state.loading || !this.state.benefitAvailable || (!prize && creditBalance < (benefit.json_data.credits || 0))}
                  className="btn btn-primary btn-block"
                  title={this.t("Canjear Beneficio")}
                  onClick={this.saveTransaction}
                />
                <div className="mt-2">
                  {!prize && (
                    <p className="text-xs mt-3 text-center text-gray-400 whitespace-pre">
                      {
                        benefit && !benefit.json_data?.credits && `No se necesitan créditos para canjearlo`
                      }
                      {
                        benefit && benefit.json_data?.credits && `Se necesitan ${benefit.json_data?.credits || 0} créditos para canjearlo.\n Actualmente tiene ${creditBalance} créditos`
                      }
                    </p>
                  )}
                   { this.state.unavailabilityReasons && this.state.unavailabilityReasons.map((reason, index) => {
                    return (
                      <p className="text-xs mt-3 text-center text-gray-400">{reason}</p>
                    )
                  })
                  }
                  {/* <p className="text-xs mt-3 text-center text-gray-400">
                      {
                        creditBalance <= (benefit.json_data.credits || 0) &&
                        `Se necesita ${benefit.json_data.credits || 0} créditos para canjear este premio.`
                      }
                      {
                        creditBalance > (benefit.json_data.credits || 0) &&
                        `Crédito disponible: ${creditBalance} de ${benefit.json_data.credits || 0} necesarios`
                      }
                    </p> */}
                  {/* <p className="text-xs mt-3 text-center text-gray-400">
                      {
                        // se ha canjado su premio x veces, pronto le llegará un mail con los datos de canje
                        `Se ha canjeado su premio ${user_uses} veces, pronto le llegará un mail con los datos de canje`
                      }
                    </p> */}
                </div>
              </div>
            </div>
          </div>
          <section className="p-4 max-w-md mx-auto">
            <h4 className="mb-1" >{this.t("Descripción")}</h4>
            <MultiLineTextDisplay className="mb-5" text={benefit.detail} />

            {benefit.json_data && benefit.json_data.instructions && (
              <>
                <h4 className="mb-1" >{this.t(`Dinámica para usar el ${benefitOrPrize}`)}</h4>
                <MultiLineTextDisplay className="mb-5" text={benefit.json_data.instructions} />
              </>
            )}
            {benefit.json_data && benefit.json_data.extra_info && (
              <>
                <h4 className="mb-1">{this.t("Aclaraciones")}</h4>
                <MultiLineTextDisplay className="mb-5" text={benefit.json_data.extra_info} />
              </>
            )}
            {benefitValues.length ? (
              <>
                <h4>{this.t("Descuentos por nivel")}</h4>
                <div className="grid gap-2 grid-cols-3 mt-2 mb-4">
                  {benefitValues.map((b, index) => {
                    return (
                      <div key={`lvl${index}`} className={"card white p-2 text-center " + (this.state.userLevel === index + 1 ? "border border-primary" : "")}>
                        <p className="mb-1">{this.t("Nivel")} {b.level}</p>
                        <span className="badge badge-primary">{b.value}</span>
                      </div>
                    )
                  })}
                </div>
              </>
            ) : null}
            {benefit.json_data && benefit.json_data.url && (
              <>
                <h4 className="mt-4">{this.t("Página web de la marca")}</h4>
                <p className="mb-4"><a className="link text-primary" target="_blank" href={benefit.json_data.url} rel='noreferrer'> {benefit.json_data.url}</a></p>
              </>
            )}
            {benefit.json_data && benefit.json_data.rules && (
              <>
                <h4 className="mb-1">{this.t("Período de validez")}</h4>
                {(benefit.json_data?.rules?.start_date && benefit.json_data?.rules?.end_date) && <p>Desde el {getDateFirstPart(benefit.json_data.rules.start_date)} hasta el {getDateFirstPart(benefit.json_data.rules.end_date)}</p>}
                {(benefit.json_data?.rules?.start_date && !benefit.json_data?.rules?.end_date) && <p>Desde el {getDateFirstPart(benefit.json_data.rules.start_date)}</p>}
                {(!benefit.json_data?.rules?.start_date && benefit.json_data?.rules?.end_date) && <p>Hasta el {getDateFirstPart(benefit.json_data.rules.end_date)}</p>}
                {(!benefit.json_data?.rules?.start_date && !benefit.json_data?.rules?.end_date) && <p>Disponible cualquier fecha</p>}
                {/* {benefit.json_data?.rules?.days_of_week ? <p>{daysOfWeekToString(benefit.json_data.rules.days_of_week)}</p> : <p>Disponible todos los días de la semana</p>} */}
              </>
            )}
            {!prize && benefitBusiness && benefitBusiness.url && (
              <>
                <h4 className="mt-4">{this.t("Más información")}</h4>
                <p className="mb-4"><a className="link text-primary" target="_blank" href={benefitBusiness.url} rel='noreferrer'> {benefitBusiness.url}</a></p>
              </>
            )}
          </section>
        </>
      )}
    </LayoutResponsiveWSmall>
  )
}
}


const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    benefit: state.benefits.current,
    benefits: state.benefits.list,
    message: state.messages.current,
    internals: state.internals,
    user: state.users.current,
    transactions: state.transactions.list,
    transaction: state.transactions.current,
    business: state.businesses.current,
    companies: state.companies.list,
    setting: state.settings.list,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSaveTransaction: (params) => dispatch(transactionsAction.saveOrUpdate(params)),
    onGetTransaction: (params) => dispatch(transactionsAction.getAll(params)),
    onSendMail: (params) => dispatch(internalsActions.sendMail(params)),
    onGetUser: (params) => dispatch(usersActions.get(params)),
    onGetBenefit: (params) => dispatch(benefitsActions.get(params)),
    onGetBenefits: (params) => dispatch(benefitsActions.getAll(params)),
    onGetBusiness: (params) => dispatch(businessesActions.get(params)),
    onGetCompanies: (params) => dispatch(companiesActions.getAll(params)),
    onGetBalance: (params) => dispatch(usersActions.getBalance(params)),
    onGetSetting: (params) => dispatch(actions.getAll(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Benefit));



