import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Form, Field } from "react-final-form";
import { WhenFieldChangesDo } from '../../libraries/forms';
import { ToastContainer, notify } from "../../libraries/notifications";

import { history } from '../../routes';
import config from "../../config";

import servicesActions from "../../context/services/actions";

import BottomNav from '../../components/commons/BottomNav';
import Loader from '../../components/commons/Loader';
import Icon from "../../libraries/icons";
import LayoutSmall from "../../components/layout/LayoutSmall";
import ServiceElement from "../../components/customs/ServiceElement";
import SearchInput from "../../components/forms/SearchInput";
import SelectInput from "../../components/forms/SelectInput";
import { capitalize, toBool } from '../../libraries/utils';

class Services extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      services: [],
      search: "",
      unique_id: this.props.auth.user.unique_id,
      viewAll: true,
    };
  }

  componentDidMount() {
    if(this.props.auth.user?.ancestors){
      let companiesIds = this.props.auth.user.ancestors.companies.map(c => c.id)
      this.setState({companiesIds, unique_id: companiesIds, viewAll: true}, this.getServices)    
    }
  }

  getServices = async () => {
    const {search, filters} = this.state
    const params = { status: config.STATUS.SERVICES.AVAILABLE, unique_id: this.state.unique_id }

    if (search && search !== ""){
      params.name = `%${search.toLowerCase()}`
    }

    await this.props.onGetAllServices(params);
    const { services } = this.props;
    if (services.error) {
      notify(this.t(services.error.message));
    } else {
      this.setState({ services: services.items });
    }
  };

  searchClear = (form) => {
    form.change('search', undefined);
    this.searchOnClick({search: ''});
  }

  searchOnClick = async (values) => {
    console.log("Search on Click", values.search);
    if (this.state.search !== values.search) this.setState({ search: values.search || '' }, () => this.getServices() );
  };

  goBack = () => {
    history.push({ pathname: config.ROUTES.HOME, state: { from: config.ROUTES.MAIN.OFFER }});
  }

  setViewAll = (viewAll) => {
    let unique_id = this.props.auth.user.unique_id
    this.setState({viewAll, unique_id: viewAll ? this.state.companiesIds : unique_id}, this.getServices)
  }

  onSubmit = (value) => {
    console.log('VALUES', value);
    this.setViewAll(toBool(value));
  }

  render() {
    const { services } = this.state;
    const options = [{ value: true, label: capitalize(this.t('all the accounts')) },{ value: false, label: capitalize(this.t('my office')) }];

    return (
      <LayoutSmall
        main={{ className: "pb-14" }}
        header={{
          title: this.t("Services"),
          className: "bg-primary",
          left: { icon: 'arrow_left', action: () => this.goBack() },
        }}
        container={{ className: "px-0" }}
      >
        {/* <SelectInput className="" selectClassName="bg-transparent p-1" input={{ name: 'select', onChange: this.changeOrigin }} options={options} noError={true} /> */}
        <ToastContainer />
        <section className="min-h-full py-6 bg-base-200 mx-auto">
          <div className="px-4">
            <Form initialValues={{}} onSubmit={this.searchOnClick}>
              {({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit} className="w-full mb-4">
                  <Field name="search" component={SearchInput} placeholder={this.t("Search")} onClick={this.searchOnClick} onClearClick={() => this.searchClear(form) }/>
                  <Field name="viewAll" component={SelectInput} className="pt-2" selectClassName="select-ghost select-sm w-40" options={options} noError={true} />
                  <WhenFieldChangesDo field="viewAll" action={({ field, value, values }) => { this.onSubmit(value); }} />
                </form>
              )}
            </Form>
          </div>
          <div>
            { this.props.services.loading && (<Loader/>) }
            {!this.props.services.loading && services.length === 0 && (
              <div className="text-center p-10">
                <Icon className="h-24 w-24 mx-auto mb-3" name="world" />
                <h4 className="mb-1">{this.t("No hay servicios")}</h4>
              </div>
            )}
            <div className="bg-white">
              { services && services.map((service, index) => {
                  return (
                    <ServiceElement 
                      key={'p' + index}
                      route={{ pathname: config.ROUTES.SERVICES.DETAIL.replace(':id', service.id), state: { from: config.ROUTES.SERVICES.LIST }}}
                      service={service}
                    />
                  )
                })
              }
            </div>
          </div>
        </section>
        <BottomNav/>
      </LayoutSmall>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.users.auth,
    services: state.services.list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetAllServices: (params) => dispatch(servicesActions.getAll(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Services));
