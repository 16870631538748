import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ToastContainer, notify } from '../../libraries/notifications';
import { history } from '../../routes';
import config from '../../config';

import Loader from '../../components/commons/Loader';
import FavorElement from '../../components/customs/FavorElement';

import productsAction from '../../context/products/actions';
import servicesAction from '../../context/services/actions';
import favorsAction from '../../context/favors/actions';
import transactionsAction from '../../context/transactions/actions';
import companiesAction from '../../context/companies/actions';
import Icon from '../../libraries/icons';

import { capitalize, toBool } from '../../libraries/utils';

import Instructive from '../../components/commons/Instructive';

import LayoutWithSidebar from '../../components/layout/LayoutWithSidebar';
import BottomNav from '../../components/commons/BottomNav';

import NewProductModal from '../../components/customs/NewProductModal';
import LayoutResponsive from '../../components/layout/LayoutResponsive';
class Favors extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      favors: [],
      unique_id: this.props.auth.user.unique_id,
      viewAll: true,
      companiesIds: null,
      newProductModalOpen: false,
    }
  }

  componentDidMount() {
    console.log('OFFER PAGE');
    let companiesIds = this.props.auth.user.ancestors.companies.map(c => c.id)
    this.setState({companiesIds, unique_id: companiesIds, viewAll: true}, this.getFavors)  
  }

  getFavors = async () => {
    await this.props.onGetFavors(
      { 
        // status: config.STATUS.FAVORS.AVAILABLE, Elimino el filtro del estado para obetener los favores que estan prestados
        unique_id: this.state.unique_id,
        order_by: "products__created_at", 
        order_direction: 'DESC' 
      });
    const { favors } = this.props;
    if (favors.error) {
      notify(this.t(favors.error.message));
    } else {
      this.setState(prevState => ({
        favors: [...prevState.favors, ...favors.items.filter(i => i?.user.id !== this.props.auth.user.id)]
      }));
    }
    console.log("FAVORS",this.state.favors)
  }

  setViewAll = (viewAll) => {
    let unique_id = this.props.auth.user.unique_id
    this.setState({viewAll, unique_id: viewAll ? this.state.companiesIds : unique_id}, this.getData)
  }

  // changeOrigin = (e) => {
  //   this.setViewAll(e.value);
  // }

  onSubmit = (value) => {
    console.log('VALUES', value);
    this.setViewAll(toBool(value));
  }
  goToNewProduct = () => {
    this.setState({newProductModalOpen: true})
    //history.push({
    //  pathname: config.ROUTES.ADD,
    //  state:{
    //    fromPath: config.ROUTES.USER.INVENTORY,
    //  }
    //})
  }
  render() {
    const { favors } = this.state;
    console.log("__favors",favors)
    const options = [{ value: true, label: capitalize(this.t('all the accounts')) },{ value: false, label: capitalize(this.t('my office')) }];
    const tabs = [
      {
        
          name: capitalize(this.t("productos")),
          onClick: () => {history.push(config.ROUTES.HOME_OPTIONS.PRODUCTS)},
          active: true
      },
        {
          name: capitalize(this.t("favores")),
          onClick: () =>{} //{history.push(config.ROUTES.HOME_OPTIONS.FAVORS)},
        },
        {
          name: capitalize(this.t("peticiones")),
          onClick: () => {history.push(config.ROUTES.HOME_OPTIONS.REQUESTS)},
        },      
    ]
    return (
      <LayoutResponsive
        main={{ className: "pb-[48px]"}}
        header={{ 
          logo: true,
          right: { icon: 'plus', action: this.goToNewProduct, buttonClassName: "bg-primary" },
          //content: <NavButtonBar options={buttonBarUpper} active={topNav} onClick={this.onNavBarClick} />
          tabs: [
            {
              name: capitalize(this.t("productos")),
              onClick: () => {history.push(config.ROUTES.HOME_OPTIONS.PRODUCTS)},

            },
            {
              name: capitalize(this.t("favores")),
              onClick: () => {},
              active: true,
            },
            {
              name: capitalize(this.t("peticiones")),
              onClick: () => {history.push(config.ROUTES.HOME_OPTIONS.REQUESTS)},
            },
          ],
        }}
 
        container={{ className: "px-0"}}
      >
      <ToastContainer/>
      <div className="px-5 border-t border-gray-100 "> 
        <Instructive slides={[
          {
            title:"Economía Circular",
            body:"Pedí prestado lo que necesitás canjeando tus puntos Shary. ¡Y no te olvides de publicar lo que quieras ofrecer, asi ganás puntos cuando te lo pidan!",
            img: "howtoborrow"
          }]} instructive={"show_offers"}> 
        <NewProductModal t={this.t} open={this.state.newProductModalOpen} setOpen={(open) => this.setState({newProductModalOpen:open})}/>
        <section className="container-fluid p-3 pb-10 w-full mx-auto">
          <div className="scrolling-wrapper mx-5">
              {tabs.map((tab, index) => {
                  return(
                      <div
                        key={index}
                        className={"px-4 py-3 text-center border-b-4 border-transparent font-medium flex-1 rounded-none" + (index === 1 ?  " border-b-secondary font-semibold text-base-content " : " text-neutral ") + ""}
                        onClick={tab.onClick}
                      >
                        <h5 className='whitespace-nowrap'>{this.t(tab.name)}</h5>
                      </div>
                  )
                  })

                }

            </div>
          <div className="flex flex-col gap-3 w-full">
            { this.props.favors.loading && (<Loader/>) }
            {!this.props.favors.loading && favors.length === 0 && (
              <div className='flex flex-col items-center gap-5 mt-5 text-neutral'>
                <Icon className="h-16 w-16" name="sad" />
                <p className="">{this.t("No favores disponibles")}</p>
              </div>
            )}
            {favors && favors.map((favor, index) => {
              return (
                  <FavorElement 
                    key={'f' + index}
                    route={{pathname: config.ROUTES.FAVORS.DETAIL.replace(':id', favor.id), state: {from: config.ROUTES.HOME_OPTIONS.FAVORS} }}
                    favor={favor}
                  />
                  )
                })
              }
          </div>
        </section>
        </Instructive>
      </div>

      {/* <BottomNav/> */}
      </LayoutResponsive>  
    ) 
  }
} 

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    products: state.products.list,
    services: state.services.list,
    favors: state.favors.list,
    requests: state.transactions.list,
    company: state.companies.current,
    companies: state.companies.list,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetProducts: (params) => dispatch(productsAction.getAll(params)),
    onGetServices: (params) => dispatch(servicesAction.getAll(params)),
    onGetCompanies: (params) => dispatch(companiesAction.getAll(params)),
    onGetCompany: (id) => dispatch(companiesAction.get(id)),
    onGetFavors: (params) => dispatch(favorsAction.getAll(params)),
    onGetAllRequests: (params) => dispatch(transactionsAction.getAll(params)),
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Favors));

