import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import config from '../../config';
import { ToastContainer, notify } from '../../libraries/notifications';
import { history } from '../../routes';

import LayoutWithSidebar from '../../components/layout/LayoutWithSidebar';
import Loader from '../../components/commons/Loader';
import Icon from '../../libraries/icons';
import BottomNav from '../../components/commons/BottomNav';
import ReturnCard from '../../components/customs/ReturnCard';
import ActivityElement from '../../components/customs/ActivityElement';

import transactionsAction from '../../context/transactions/actions';
import productsAction from '../../context/products/actions';
import MessagesActions from '../../context/messages/actions';
import internalsActions from '../../context/internals/actions';
import notificationsActions from '../../context/notifications/actions';
import { capitalize } from '../../libraries/utils';

import AutoQuery from '../../components/commons/AutoQuery';
import RequestElement from '../../components/customs/RequestElement';
import LoanCard from '../../components/customs/LoanCard';
import LayoutResponsive from '../../components/layout/LayoutResponsive';

class Returns extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      loans: [],
      loans_history: [],
      subtype: 'product',
      notifications: {
        product: false,
        favor: false,
      },
    }
  }
  
  componentDidMount() {
    console.log('Returns');
    this.getMyReturns();
    this.getNotifications()
    this.getRequests();
  }

  showInstructions = () => {
    const swalWithStyle = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary mr-2',
        cancelButton: 'btn btn-ghost',
      },
      buttonsStyling: false
    })
    swalWithStyle.fire({ 
      title:this.t("Devoluciones"),
      text: this.t("Recuerda que debes confirmar cuando recibas el producto , botón 'RECIBÍ', así como cuando lo devuelvas, 'DEVOLVÍ'."),
      icon: 'info',
      showCancelButton: false,
      confirmButtonText: this.t("Ok"),
    })
  }

  getMyReturns = async ()=> {
    await this.props.onGetMyReturns( { type: config.TRANSACTIONS.BORROW.TYPE, created_by: this.props.auth.user.id } );
    const { loans } = this.props;
    if (loans.error) { 
      notify(this.t(loans.error.message));
    } else {
      //show instructions
      this.setState({
        loans: loans.items.filter((loan) => (
          ![config.TRANSACTIONS.BORROW.STATUS.CANCELED,
          config.TRANSACTIONS.BORROW.STATUS.COMPLETED,
          config.TRANSACTIONS.BORROW.STATUS.ON_OWNER,
          ].includes(loan.status) && !loan.json_data.product_rating
        )),
        loans_history: loans.items.filter((loan) => (
          [config.TRANSACTIONS.BORROW.STATUS.CANCELED,
            config.TRANSACTIONS.BORROW.STATUS.COMPLETED,
            config.TRANSACTIONS.BORROW.STATUS.ON_OWNER
          ].includes(loan.status)
        )),
      });
      
    }    
  }

  getRequests = async () => {
    const params = {
      type: config.TRANSACTIONS.REQUEST.TYPE,
      owner: this.props.auth.user.unique_id,
      created_by: this.props.auth.user.id,
      order_by: "created_at",
      order_direction: 'DESC'
    }
    console.warn(params);
    await this.props.onGetAllRequests(params);
    const { requests } = this.props;
    if (requests.error) {
      notify(this.t(requests.error.message));
    } else {
      this.setState({ requests: requests.items});
    }
  }

  sendCancelMail = async (loan, reject_reason) => {
      let mailData = {
        template: "borrow_cancel_owner",
        locale: "es",
        to:loan?.json_data?.owner?.email,
        first_name: loan?.json_data?.owner?.first_name,
        last_name:  loan?.json_data?.owner?.last_name,
        params: {
            borrow_name: loan?.json_data?.product ? loan.json_data.product.name : loan.json_data.offer.name,
            user_name: `${this.props.auth.user.first_name} ${this.props.auth.user.last_name}`,
            reason_name: reject_reason,
            // subject: "Préstamo cancelado",
            line_1: `${loan.json_data.owner?.first_name} ${loan.json_data.owner?.last_name}`,
            line_2: `${this.props.auth.user.first_name} ${this.props.auth.user.last_name}`,
            line_3: `${loan.json_data.product ? loan.json_data.product.name : loan.json_data.offer.name}`,
            line_4: reject_reason,
            //: `Hola ${loan.json_data.owner?.first_name} ${loan.json_data.owner?.last_name}`,
            //: `Te informamos que ${this.props.auth.user.first_name} ${this.props.auth.user.last_name}`,
            //: ` tuvo que cancelar el siguiente pedido que le hiciste: ${loan.json_data.product ? loan.json_data.product.name : loan.json_data.offer.name}`,
            //: `Por la siguiente razón: ${reject_reason}`,
            //line_5: `Pero no te quedes con las ganas, buscá dentro de la comunidad quien más puede brindartelo.  Si no aparece otra opción en el muro de ofertas, podés agregarlo como algo que buscás para que alguien vea la solicitud y te lo pueda ofrecer.`,
        }
      }

     await this.props.onSendMail(mailData);
     const internals = this.props.internals;
     if (internals.error) {
       notify(this.t(internals.error.message));
     }
  }

  getNotifications = async () => {
    await this.props.onGetAllNotifications({target: this.props.auth.user.id})
    const notifications = this.props.notifications?.items;
    if (notifications && notifications.length > 0) {
      const productNotifications = notifications.filter(item => item.json_data && item.json_data.product && item.readed === 0).length;
      const favorNotifications =  notifications.filter(item => item.json_data && item.json_data.favor && item.readed === 0).length;
      this.setState({
        notifications: {
          product: !!productNotifications,
          favor: !!favorNotifications,
        }
      })
    }
  }

  cancelTrx = async (loan) => {
    console.log('CANCEL TRANSACTION', loan.id);
    const message = [
      this.t(`Estás cancelando el pedido `),
      this.t(`del producto`),
      ` ${loan.json_data.product ? loan.json_data.product.name : loan.json_data.offer.name}`
    ];

    const swalWithStyle = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary mr-2',
        cancelButton: 'btn btn-ghost',
      },
      buttonsStyling: false
    })
     const result = await swalWithStyle.fire({
          title: this.t("¿Cuál es el motivo?"),
          input: "text",
          text: message.join(''),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.t("Cancelar"),
          cancelButtonText: this.t("Cerrar"),
          inputValidator: (value) => {
            if (!value) {
              return 'Por favor, escribir un motivo'
            }
          }
        })
        .then((result) => {
          return result
        });
    console.log('ANSWER', result);

    if (result && result.isConfirmed) {
       
      await this.props.onChangeTransactionState({
        id: loan.id,
        status: config.TRANSACTIONS.BORROW.STATUS.CANCELED,
        json_data: {
          canceled_by: "borrower"
        },
      })
      console.log('loan', this.props.loan);
      if (this.props.loan.error) { 
        notify(this.t(this.props.loan.error.message));
      } else {

        await this.props.onChangeProductState({
          id: loan.json_data.product ? loan.json_data.product.id : loan.json_data.offer.id,
          status: config.STATUS.PRODUCTS.AVAILABLE
        })
        const product = this.props.product; 
        console.log('AFTER PRODUCT STATUS CHANGING', product);
        if (product.error) { 
          notify(this.t(product.error.message));
        } else {
          this.sendCancelMail(loan, result.value)
          Swal.fire('', this.t("Has cancelado el pedido"), 'success');
          this.getMyReturns();
        }

      }          

    } 
  
  }

  claimTrx = async (loan) => {
    console.log('CANCEL TRANSACTION', loan.id);
    const message = [
      this.t(`Estás reclamando el pedido `),
      this.t(`de`),
      ` ${loan.json_data.product.name}`
    ];

    const result = await Swal.fire({
        title: this.t("Reclamar pedido"),
        input: "text",
        text: message.join(''),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.t("Reclamar"),
        cancelButtonText: this.t("Cancel"),
      })
      .then((result) => {
        return result
      });
    console.log('ANSWER', result);
    

    if (result && result.isConfirmed) {

      let notificationData = {  
        type:config.MESSAGES.TYPE.NOTIFICATION,
        source:loan.json_data.borrower.id,
        target: loan.json_data.owner.id,
        related_to: loan.json_data.product.id,
        parent: " " ,
        subject: loan.json_data.product.type,
        body: " esta reclamando sobre " + loan.json_data.product.name + ": " + result.value,
        json_data: {
          product:loan.json_data.product,
          redirect_notification_route: config.ROUTES.RETURNS,
        },
      }
      
      await this.props.onSaveNotification(notificationData);
      console.log('AFTER SAVE NOTIFICATION');
      const notification = this.props.message;
      if (notification.error) { 
        notify(this.t(notification.error.message));
      } 
      
      Swal.fire('', this.t("Reclamo realizado"), 'success');
      

    } else if (result && result.isDismissed) {
      Swal.fire('', this.t("Se canceló la acción"), 'error');
    }

  }

  onClickCard = (loan) => {
      if(loan.json_data.product){
        history.push(
          {
            pathname: config.ROUTES.PRODUCTS.DETAIL.replace(':id', loan.json_data.product.id),
            state:{
              fromPath: config.ROUTES.USER.RETURNS
            }
          }
        )
      } else {
        history.push(
          {
            pathname: config.ROUTES.PRODUCTS.DETAIL.replace(':id', loan.json_data.offer.id),
            state:{
              fromPath: config.ROUTES.USER.RETURNS
            }
          }
        )                              
      }
  }
  
  gotoProduct = (p) => {
    let route = config.ROUTES.PRODUCTS.DETAIL
    let product_id = p.json_data.product ? p.json_data.product.id : p.json_data.offer.id
    if(p.sub_type === 'favor') route = config.ROUTES.FAVORS.DETAIL
    if(p.sub_type === 'service') route = config.ROUTES.SERVICES.DETAIL
    history.push({ pathname: route.replace(':id', product_id), state: { fromPath: config.ROUTES.USER.RETURNS }})
  }


  render() {
    const { loans, loans_history, notifications, subtype, requests } = this.state;
    const getSubTypeString = (subtype) => subtype === config.TRANSACTIONS.BORROW.SUB_TYPE.FAVOR ? "favor" : "servicio"

    const optionsDef = {product: 'product', favor: 'favor', requests: 'Peticiones'};

    const options = Object.keys(optionsDef).map((o) => { return { value: o.toLocaleLowerCase(), label: this.t(capitalize(optionsDef[o].toLocaleLowerCase())) }});

    const products = loans.filter( l => l.sub_type === config.TRANSACTIONS.BORROW.SUB_TYPE.PRODUCT );
    const services = loans.filter( l => l.sub_type === config.TRANSACTIONS.BORROW.SUB_TYPE.SERVICE );
    const favors = loans.filter( l => l.sub_type === config.TRANSACTIONS.BORROW.SUB_TYPE.FAVOR );

    const empty = loans && (loans.length === 0 || 
      subtype === 'product' && products.length === 0 ||
      subtype === 'requests' && services.length === 0 ||
      subtype === 'favor' && favors.length === 0
    ); // Para saber si una sección está vacía y mostrar el mensaje correspondiente, sino solo se muestra seccion vacia cuando no hay nada en loans
    const getActions = (_loan) => { 
      return _loan.json_data.product ? [
      { status: [/* config.TRANSACTIONS.BORROW.STATUS.NEW, */ config.TRANSACTIONS.BORROW.STATUS.ACCEPTED], className: 'btn-primary btn-sm ',
        title: this.t("Recibí"),
        disabled: (loan) => loan.status !== config.TRANSACTIONS.BORROW.STATUS.ACCEPTED,
        onClick: (loan) => history.push(config.ROUTES.USER.CONFIRM_LOAN.replace(':id', loan.id))
      },

      { status: [config.TRANSACTIONS.BORROW.STATUS.NEW,], className: 'btn-primary btn-sm ',
        title: this.t("Pendiente"),
        disabled:  (loan) => loan.status === config.TRANSACTIONS.BORROW.STATUS.NEW,
      },

      { status: [config.TRANSACTIONS.BORROW.STATUS.NEW, config.TRANSACTIONS.BORROW.STATUS.ACCEPTED ], className: 'btn-error btn-sm',
        title: this.t("Cancelar"),
        onClick: (loan) => this.cancelTrx(loan)
      },

      { status: [config.TRANSACTIONS.BORROW.STATUS.ON_USER], className: 'btn-success btn-sm',
      title: this.t("Devolví"),
      onClick: (loan) => history.push(config.ROUTES.USER.CONFIRM_RETURN.replace(':id', loan.id))
      },

      //{ status: [config.TRANSACTIONS.BORROW.STATUS.NEW, config.TRANSACTIONS.BORROW.STATUS.ACCEPTED, config.TRANSACTIONS.BORROW.STATUS.ON_USER ], className: 'btn btn-outline btn-primary btn-sm',
      //  title: this.t("Reclamar"),
      //  onClick: (loan) => this.claimTrx(loan)
      //},
    
     /*  { status: [config.TRANSACTIONS.BORROW.STATUS.ON_USER], className: 'btn btn-sm',
        title: this.t("Extender"),
        onClick: (loan) => console.log('EXTEND', loan.id)
      }, */
      { status: [config.TRANSACTIONS.BORROW.STATUS.COMPLETED], className: 'btn-success btn-sm',
        title: this.t("Calificar"),
        onClick: (loan) => history.push(config.ROUTES.USER.CONFIRM_RETURN.replace(':id', loan.id), { state: { status: config.TRANSACTIONS.BORROW.STATUS.ON_OWNER }})
      },
    ] : [
      { status: [/* config.TRANSACTIONS.BORROW.STATUS.NEW, */ config.TRANSACTIONS.BORROW.STATUS.ACCEPTED], className: 'btn-primary btn-sm ',
        title: this.t("Ya recibí el " + getSubTypeString(_loan.sub_type)),
        disabled: (loan) => loan.status !== config.TRANSACTIONS.BORROW.STATUS.ACCEPTED,
        onClick: (loan) => history.push(config.ROUTES.USER.CONFIRM_LOAN.replace(':id', loan.id))
      },
      { status: [config.TRANSACTIONS.BORROW.STATUS.NEW, config.TRANSACTIONS.BORROW.STATUS.ACCEPTED], className: ' btn-error btn-sm ',
        title: this.t("Cancelar"),
        onClick: (loan) => this.cancelTrx(loan),
        //disabled:  (loan) => loan.status === config.TRANSACTIONS.BORROW.STATUS.NEW,
      },
       { status: [config.TRANSACTIONS.BORROW.STATUS.COMPLETED], className: 'btn-success btn-sm',
        title: this.t("Calificar"),
        onClick: (loan) => history.push(config.ROUTES.USER.CONFIRM_RETURN.replace(':id', loan.id), { state: { status: config.TRANSACTIONS.BORROW.STATUS.ON_OWNER }})
      },
      { status: [config.TRANSACTIONS.BORROW.STATUS.ON_OWNER], className: 'btn-primary btn-sm ',
        title: this.t("Confirmación de realización pendiente"),
        disabled: () => true,
      },
    ]}

    return (
      <LayoutResponsive
        main={{ className: ""}}
        header={{ 
          className: "",
          title: this.t("Lo que pedí"),
        }}
      >
        <ToastContainer/>
        <AutoQuery getData={this.getMyReturns} />
        <div className="container mx-auto">
          <div className="">
            <div className='flex justify-between border-t'>
              {options.map((o,i)=>(
                <div
                  key={i}
                  className={"px-4 py-3 text-center border-b-4 border-transparent font-medium flex-1 rounded-none " + (o.value === subtype ? " border-b-secondary font-semibold text-base-content " : " text-neutral ") + ""}
                  onClick={() => this.setState({subtype: o.value},()=>console.log(products,services,favors))}
                >
                  <h5 className='flex items-center justify-center gap-2 whitespace-nowrap'>{this.t(o.label)}
                    {notifications[o.value] &&
                        <div className="badge badge-sm bg-error text-white border-none" />
                      }
                  </h5>
                </div>
              ))}
            </div>
            { this.props.loans.loading && (<Loader/>) }
            {!this.props.loans.loading && empty && (
              <div className="text-center p-10 text-gray-400">
                <Icon className="h-14 w-14 mx-auto mb-2" name="returns" />
                <p className="mb-1">{this.t("Sección vacía")}</p>
              </div>
            )}

            <div className='mt-3 p-3'>
              {subtype === 'product' && (
                products.map((loan, index) => (
                  <ReturnCard className="mb-4" key={index} loan={loan} actions={getActions(loan)}
                    statuses={config.STATUS.TRANSACTIONS}
                    onClick={() => {
                      if(loan.json_data.product){
                        history.push({
                          pathname: config.ROUTES.PRODUCTS.DETAIL.replace(':id', loan.json_data.product.id),
                          state:{fromPath: config.ROUTES.USER.RETURNS}
                        })
                      } else {
                        history.push({
                          pathname: config.ROUTES.PRODUCTS.DETAIL.replace(':id', loan.json_data.offer.id),
                          state:{fromPath: config.ROUTES.USER.RETURNS}
                        })                              
                      }
                    }}
                  />
                ))
              )}
 
              {subtype === 'favor' && (
                favors.map((loan, index) => (
                  <ReturnCard className="mb-4" key={index} loan={loan} actions={getActions(loan)}
                    statuses={config.STATUS.TRANSACTIONS}
                    onClick={() => {
                      if(loan.json_data.product){
                        history.push({
                          pathname: config.ROUTES.PRODUCTS.DETAIL.replace(':id', loan.json_data.product.id),
                          state:{fromPath: config.ROUTES.USER.RETURNS}
                        })
                      } else {
                        history.push({
                          pathname: config.ROUTES.PRODUCTS.DETAIL.replace(':id', loan.json_data.offer.id),
                          state:{fromPath: config.ROUTES.USER.RETURNS}
                        })                              
                      }
                    }}
                  />
                ))
              )}

              {subtype === 'requests' && (
                requests.map((request, index) => (
                  <RequestElement
                    key={'p' + index}
                    route={{
                      pathname: config.ROUTES.REQUESTS.PREVIEW.replace(":id", request.id),
                      state:{fromPath: config.ROUTES.USER.RETURNS}
                    }} 
                    request={request}
                  />
                ))
              )}
            </div>
          </div>
          <div className="mb-20">
            {loans_history.length > 0 && <h2 className="px-4 mb-2">{this.t("Historial")}</h2>}
            <div className="bg-white shadow-lg">
              {loans_history.map((h, index) => {
                return(
                  <ActivityElement
                    key={index}
                    activity={h}
                    // user={this.props.auth.user.id}
                    statuses={config.STATUS.TRANSACTIONS}
                    onClick={()=>{this.gotoProduct(h)}}
                  />)
              })}
            </div>
          </div>          
        </div>
        <div><br/></div> {/*fixme css: esto está para que el BottomNav no tape el historial*/}
        {/* <BottomNav/> */}
      </LayoutResponsive>
    ) 
  }
}


const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    loans: state.transactions.list,
    loan: state.transactions.current,
    product: state.products.current,
    message: state.messages.current,
    internals: state.internals,

    requests: state.transactions.list,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetMyReturns: (params) => dispatch(transactionsAction.getAll(params)),
    onChangeTransactionState: (params) => dispatch(transactionsAction.saveOrUpdate(params)),
    onChangeProductState: (params) => dispatch(productsAction.saveOrUpdate(params)),
    onSaveNotification: (params) => dispatch(MessagesActions.saveOrUpdate(params)),
    onSendMail: (params) => dispatch(internalsActions.sendMail(params)),
    onGetAllNotifications: (params) => dispatch(notificationsActions.getAll(params)),
    onGetAllRequests: (params) => dispatch(transactionsAction.getAll(params)),
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(Returns)) ;


