import React, { useState, useReducer, useEffect } from 'react';
import useUpdateEffect from '../../libraries/hooks/useUpdateEffect'
import { Form, Field } from 'react-final-form';
import FormLayout from '../../components/forms/FormLayout';
import LayoutSmall from '../../components/layout/LayoutSmall';
import TextWithIconButtonInput from '../../components/forms/TextWithIconButtonInput';
import { capitalizePhrase, capitalize, convertUTCDateToLocalDate, uuidv4 } from '../../libraries/utils';
import { getOwner } from '../../libraries/utils';
import Loader from '../../components/commons/Loader';
import Swal from 'sweetalert2';
import Icon from '../../libraries/icons';
import messagesActions from '../../context/messages/actions';
import internalsActions from '../../context/internals/actions';
import filesActions from '../../context/files/actions'
import { connect } from 'react-redux';
import { ToastContainer, notify } from '../../libraries/notifications';
//import Picker from 'emoji-picker-react';
import { useTranslation } from 'react-i18next';
import config from '../../config';
import { history } from '../../routes';
import { withTranslation } from 'react-i18next';
import LayoutResponsiveWSmall from '../../components/layout/LayoutResponsiveWSmall';

const alignment = (message, owner) => {
  return message.source.id === owner || message.source === owner ? 'items-end' : 'items-start'
}
const style = (message, owner, chat, i) => {
  const prevMessage = chat[i - 1]
  const isPrevMessageDefined = prevMessage !== undefined
  return (message.source.id === owner || message.source === owner ? `text-black bg-gray-300 ml-2 md:ml-8 ${(!isPrevMessageDefined || prevMessage.source.id !== message.source.id) && 'rounded-tr-none mt-2'}` : `text-white bg-primary mr-2 md:mr-8 ${(!isPrevMessageDefined || prevMessage.source.id !== message.source.id) && 'rounded-tl-none mt-2'}`) + `${message.json_data?.deleted_for_all ? ' italic' : ' '}`
}

const name = (message, chat, i, name) => {
  const prevMessage = chat[i - 1]
  return prevMessage?.source.id !== message.source.id ? <span className='text-xs px-1 py-1 text-gray-400'>{capitalizePhrase(message.source.name || name)}</span> : null
}

const seen = (seenIndex, chat, owner, i) => {
  return seenIndex === i && (i === chat.length - 1 || chat[i + 1].source.id === owner || chat[i + 1].source === owner)
}
const showDate = (current, previous, t) => {
  const currentDate = convertUTCDateToLocalDate(current?.created_at).getDate(),
    previousDate = convertUTCDateToLocalDate(previous?.created_at).getDate(),
    offset = currentDate - previousDate,
    today = new Date().getDate(),
    wasToday = today === currentDate && (currentDate !== previousDate),
    wasYesterday = today === currentDate + 1 && (currentDate !== previousDate),
    wasAnotherDate = offset > 1 || isNaN(previousDate)
  if (wasToday) return { show: true, text: capitalize(t('today')) }
  else if (wasYesterday) return { show: true, text: capitalize(t('yesterday')) }
  else if (wasAnotherDate) return { show: true, text: current.created_at.slice(0, 10) }
  else return { show: false, text: null }
}
const findLastSeen = (chat, owner) => {
  chat = chat.map(message => message.target?.id !== owner ? message.readed : 0)
  console.log('OWNER', owner)
  console.log('CHAT READED', chat)
  return chat.lastIndexOf(1)
}
const focusInput = () => {
  Array.from(document.getElementsByName('body'))[0].focus()
}


class AnswerQuestionClass extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      pickerOpen: false,
      loadingMessages: true
    }
  }

  componentDidMount() {
    const params = this.props.match.params;
    if (!params.root_id) {
      history.push(config.ROUTES.MESSAGES)
    }
    this.getChat(params.root_id);

  }

  isOwner = async (source) => {
    const bool = source?.id === this.props.auth.user.id
    return bool
  }

  scrollToBottom = () => {
    const chatHTML = document.getElementById('chat');
    chatHTML?.scrollTo(0, chatHTML.scrollHeight);
  }

  getChat = async (root_id) => {
    this.setState({ loadingMessages: true })
    let params = {}
    params.where = {
      custom: {
        id: root_id,
        comparison: 'OR',
        parent: root_id
      }
    }
    await this.props.onGetAllMessages(params);
    const { messages } = this.props;
    if (messages.error) {
      notify(this.t(messages.error.message));
    } else {
      let owner = this.props.auth.user.id
      const chat = messages.items.filter(e => (e.source.id === owner || e.source === owner) ? !e.json_data['deleted_for_source'] : !e.json_data['deleted_for_target'])
      const root = messages.items.find(e => e.parent === "root")
      if(!root){
        history.push(config.ROUTES.MESSAGES)
        return
      }
      // alert(JSON.stringify(this.isOwner(root?.source)))
      const isOwnerChat = await this.isOwner(root?.source)
      const chatWith = isOwnerChat ? root.target : root.source
      const image_loading = false

      const readed = messages.items.filter(e => e.source.id !== owner)

      const readedMessage = readed.filter(m => m.readed === 0)
      for (const message of readedMessage) {
        await this.markAsReaded(message.id, isOwnerChat)
      }
      if (root.json_data[`unread_messages_for_${isOwnerChat ? 'source' : 'target'}`] > 0) {
        await this.markRootAsReaded(root, isOwnerChat);
      }

      const seenIndex = findLastSeen(chat, owner)
      this.setState({ chat, root, chatWith, image_loading, owner, messages, seenIndex })
    }
    this.setState({ loadingMessages: false }, () => { this.scrollToBottom(); })
  }

  markAsReaded = async (id) => {
    const message_ = {
      id,
      readed: 1,
    }
    await this.props.onSendMessage(message_);
    const { message } = this.props;
    if (message.error) {
      notify(this.t(message.error.message));
    }
  }

  markRootAsReaded = async (root, isOwner) => {
    const obj = {
      id: root.id,
      json_data: {
        ...root.json_data, [`unread_messages_for_${isOwner ? 'source' : 'target'}`]: 0
      }
    }
    await this.props.onSendMessage(obj);
    const { message } = this.props;
    if (message.error) {
      notify(this.t(message.error.message));
    }
  }

  onSubmit = async (values, form) => {
    const { root, owner, chatWith } = this.state
    let chat_ = [...this.state.chat]
    let chat_last_message = chat_.length - 1
    if (this.validateForm(values)) {
      // create an object
      const message_ = {
        ...values,
        parent: this.state.root.id,
        subject: this.state.root.subject,
        source: this.props.auth.user.id,
        target: this.state.chatWith.id,
        type: config.MESSAGES.TYPE.QUESTION,
        loading: true,
        json_data: {
          _id: uuidv4(),
        }
      }
      this.setState({ chat: [...chat_, message_] })

      let newMessage = null
      await this.props.onSendMessage(message_);
      const { message } = this.props;
      if (message.error) {
        notify(this.t(message.error.message));
      } else {
        newMessage = { ...message.item }
      }

      // let last_m = chat_[chat_last_message]
      const isOwnerChat = await this.isOwner(root.source);
      chat_[chat_last_message].json_data.answered = 1
      const obj = {
        id: root.id,
        json_data: {
          last_message_at: new Date().toISOString(),
          last_message_from: isOwnerChat ? 'source' : 'target',
          last_message_portion: values.body,
          answered: 1,
        }
      }
      const unreadMessages = [...chat_, message_].map(message => message.target.id !== owner ? message.readed : 1).filter(e => !e).length
      if (unreadMessages > 0) {
        obj.json_data = {
          ...obj.json_data,
          [`unread_messages_for_${!isOwnerChat ? 'source' : 'target'}`]: unreadMessages
        }
      }

      await this.props.onSendMessage(obj);
      const { message: patchedMessage } = this.props;
      if (patchedMessage.error) {
        notify(this.t(patchedMessage.error.message));
      }
      else{
        this.sendMail(values.body, chatWith)
      }
      form.reset()
      this.setState({ chat: [...chat_, newMessage] }, () => { this.scrollToBottom(); })
    }
  }
  sendMail = async (message, target) => {
    const { root } = this.state
    const { user } = this.props.auth
    let mailData = {
    template: "message_notification",
    locale: "es",
    to: target.email,
    first_name: "",
    last_name:  "",
    params: {
        // subject: "Te enviaron un mensaje",
        user: `${capitalize(user?.first_name)} ${capitalize(user?.last_name)}`,
        target: capitalizePhrase(target.name),
        message: message,
        message_route: config.ROUTES.QUESTION.replace(':root_id', root.id),
    }
  }
  console.log("____MYPROPS mail sended", target.email)

  this.props.onSendMail(mailData);
  const internals = this.props.internals;
  if (internals.error) {
   notify(this.t(internals.error.message));
  }
}

  validateForm = (values) => {
    return values.body && true
  };


  gotoProfile = (id) => {
    history.push({
      pathname: config.ROUTES.USER.PUBLIC_PROFILE.replace(':id', id),
      state: {
        fromPath: config.ROUTES.QUESTION.replace(":root_id", this.props.match.params.root_id)
      },
    })

  }

  gotoProduct = (id) => {
    history.push({
      pathname: config.ROUTES.PRODUCTS.DETAIL.replace(':id', id),
      state: {
        fromPath: config.ROUTES.QUESTION.replace(":root_id", this.props.match.params.root_id)
      },
    })
  }

  render() {
    const { root, chatWith, messages, image_loading, chat, isOwnerChat, seenIndex, loadingMessages, owner, } = this.state;
    const allMessagesAnswered = () => false//() => chat.map(m => m?.json_data?.answered).filter(m => m !== undefined).every(m => m === 1)
    console.log("TESTT")
    return (
      <LayoutResponsiveWSmall
        main={{ className: "" }}
        header={{
          className: "w-auto  flex justify-start p-3",
          left: { icon: 'arrow_left', action: () => { history.push(config.ROUTES.MESSAGES) } },
          title: this.t(root && capitalizePhrase(`${root?.json_data.product_name} - ${chatWith?.name}`))
        }}
        container={{ className: "px-0 relative" }}
      >
        <ToastContainer />
        {!loadingMessages && <>

          {/* LOADER */}
          {(!messages.loading && !image_loading) || <div className='flex absolute h-screen top-0 w-full bg-base-200 z-10'><Loader /></div>}
          {/* CHAT */}
          <div className='flex flex-col bg-base-200 p-2 h-full w-full mt-10'>
            {root && (
              <div className="bg-white flex flex-col justify-between -m-2">
              {root?.json_data?.product_img &&  <div className="flex items-center justify-center overflow-hidden h-48" onClick={() => { this.gotoProduct(root.related_to) }}>
                  <img alt="imagen" src={root.json_data.product_img} />
                </div>}
                <div className="overflow-hidden">
                  <div className="p-3 leading-tight">
                    <div>
                      <p className="link link-accent" onClick={() => { this.gotoProfile(chatWith?.id) }}>{capitalizePhrase(chatWith?.name)}</p>
                      <p className="link link-accent" onClick={() => { this.gotoProduct(root.related_to) }}>{root.json_data.product_name}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}


            {/* MESSAGES CONTAINER */}
            <div id='chat' className='flex-grow overflow-y-scroll scrollbar mt-3'>
              {chat.map((message, i, array) => {
                const date = convertUTCDateToLocalDate(message['created_at'])
                const time = date.toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric' })
                const { show, text } = showDate(message, array[i - 1], this.t)
                return (
                  <div key={message.id || 'load' + i}>
                    {show && <div className='flex justify-center'><p className='px-3 py-1 rounded-full  text-xs bg-gray-200 text-gray-500 w-max my-4'>{text}</p></div>}
                    <div id={`message${i}`} className={`flex flex-col mb-1 ${alignment(message, owner)}`}>
                      {/* {name(message, array, i, user.name)} */}
                      <span className={`px-4 break-all py-1 items-end flex justify-end rounded-xl text-sm ${style(message, owner, array, i)}`}>
                        <span className='flex flex-col'>
                          {message?.json_data?.picture && !message?.json_data?.deleted_for_all && <img className='image-message mb-2' src={message.json_data.picture} alt="" />}
                          {message.body}
                        </span>

                        <span className='text-xs ml-4 break-normal'>
                          {message.loading ?
                            <Loader containerClassName="px-1 py-1 ml-2 relative" spinnerClassName="text-secondary h-3 w-3" />
                            :
                            time}
                        </span>
                      </span>
                      {seen(seenIndex, array, owner, i) && <span className='text-xs px-4 py-1 text-gray-400'>{capitalize(this.t('seen'))}</span>}
                    </div>
                  </div>
                )
              })}
            </div>

            {/* MESSAGE INPUT */}
            {isOwnerChat ?
              <p className='text-center p-3 text-gray-400'>
                {root?.json_data?.answered ? this.t('Ya respondieron a tu pregunta, puede hacer otra desde la pagina del producto') : this.t('Tu pregunta fue enviada, ¡recibirás una respuesta pronto!')}
              </p>
              : ((allMessagesAnswered()) ?
                <p className='text-center p-3 text-gray-400'>{this.t('Ya enviamos tu respuesta')}</p>
                : (
                  <Form
                    initialValues={{}}
                    onSubmit={this.onSubmit}
                    mutators={{
                      insertEmoji: (args, state, utils) => {
                        messages.loading ||
                          utils.changeValue(state, 'body', () => (args[1] || '') + args[0].emoji)
                      },
                    }}
                  >
                    {({ handleSubmit, form, values }) => {
                      return (
                        <FormLayout form={form} onSubmit={handleSubmit}>
                          <div className='w-full mb-2'>
                            <div className='input flex items-center m-1'>
                              {/*<div className='border-r pr-2'>
                              <button type='button' className={`flex ${ messages.loading || root?.json_data?.answered ? 'disabled': null}`}>
                                <Icon className="h-4 w-4" name={pickerOpen ? 'close': "emoji_face"}
                                  onClick={ ()=> {
                                  if(root?.json_data?.answered) return
                                    //setPickerOpen(!pickerOpen)
                                    //focusInput()
                                  }} 
                                />
                              </button>
                            </div>*/}

                              <Field
                                name="body"
                                component={TextWithIconButtonInput}
                                placeholder={capitalize(this.t('write your answer'))}
                                readOnly={messages.loading}
                                icon={'paper_airplane'}
                                inputClassName={'pl-2 ml-2'}
                              />
                            </div>
                            {/*<div className={!pickerOpen ? 'hidden' : null}>
                            <Picker 
                              className='w-full'
                              searchPlaceholder='Search emojis'
                              pickerStyle={{ width: '100%', height: '40vh' }}
                              //onEmojiClick={(e, emoji)=> {
                              //  form.mutators.insertEmoji(emoji, values.body)
                              //  focusInput()
                              //}}
                              disableAutoFocus
                            />
                          </div>*/}
                          </div>
                        </FormLayout>
                      );
                    }}
                  </Form>
                )
              )}
          </div>
        </>}
      </LayoutResponsiveWSmall>
    )
  }
}


const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    message: state.messages.current,
    messages: state.messages.list,
    internals: state.internals,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAllMessages: (params) => dispatch(messagesActions.getAll(params)),
    onGetMessage: (id) => dispatch(messagesActions.get(id)),
    onSendMessage: (params) => dispatch(messagesActions.saveOrUpdate(params)),
    onSendMail: (params) => dispatch(internalsActions.sendMail(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(AnswerQuestionClass));

