import React from 'react';
import { useTranslation } from 'react-i18next';
import IconPoints from './IconPoints';
import IconCoins from './IconCoins';
import { getObjectWithJsonDataToFormValues, isFunction } from '../../libraries/utils';
import { zonedTimeToUtc } from 'date-fns-tz';
import config from '../../config';
import logo from '../../assets/img/logo.png';
import Button from '../commons/Button';
import { addDays, endOfDay, isAfter, isBefore, startOfDay } from 'date-fns';
import ButtonIcon from '../commons/ButtonIcon';
import { history } from '../../routes';

//////////////////////////////////////////////////////////////////////////////////////////
//////////FIXME: ESTO NO DEBERÍA SER UN SOLO COMPONENTE GENÉRICO, ES UN QUILOMBO//////////
//////////////////////////////////////////////////////////////////////////////////////////
export default function PublicActivityCard({ statuses,types, sub_types, activity, user, ...rest }) {
  const { t, i18n } = useTranslation(); 
  // console.log({ statuses,types, sub_types, activity, user, ...rest });
  
  let {  coins, points, borrower, owner, status, type, sub_type, initiative,product,created_at, action, description, created_by, action_img, offer, canceled_by} = getObjectWithJsonDataToFormValues(
    activity,
    ['id',  'coins', 'points','borrower','owner','status','type','sub_type','initiative','product', 'created_at', 'action', 'description','created_by', 'action_img', 'offer', 'canceled_by']
  );

  if(activity.json_data.action){
    owner = { id: activity.json_data.user.id };
  }

  const fullname = (user) => {
    return user ? `${user.first_name} ${user.last_name}`: '';
  }
  const nextDay= addDays(new Date(created_at),1)
  const nextDayEnd= endOfDay(nextDay)
  const dateAndTime = new Date();
  const activityDate = created_at;

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const utcDate1 = zonedTimeToUtc(dateAndTime, timeZone);
  const utcDate2 = zonedTimeToUtc(activityDate, timeZone);


  const timeRemains = t("timeRemains", { date: new Date(created_at) })
  
  const getSubTypeString = (subtype) => subtype === config.TRANSACTIONS.BORROW.SUB_TYPE.FAVOR ? "favor" : "servicio"
  const serviceCompleted = (status, subtype) => (status === config.TRANSACTIONS.BORROW.STATUS.ON_OWNER) && (subtype === config.TRANSACTIONS.BORROW.SUB_TYPE.FAVOR || subtype === config.TRANSACTIONS.BORROW.SUB_TYPE.SERVICE)
  const gotoReclamo = () => {
    history.push({
      pathname: config.ROUTES.SEND_MESSAGE,
      state: {
        fromPath:config.ROUTES.HOME,
        reclamo: {
          type: "reclamo",
          related_to: activity?.id,
        },
        }
      }
    );
  }
  return (
    <div className="flex p-4 border-b border-base-200 gap-4" onClick={rest.onClick}>
        {(product?.img || initiative?.img || action_img) && <div className="max-w-[4rem] w-full overflow-hidden flex justify-center items-center">
        {type === config.TRANSACTIONS.BORROW.TYPE ?
          sub_type === config.TRANSACTIONS.BORROW.SUB_TYPE.PRODUCT ? 
          <img alt="" src={product?.img}/> :
          <></> 
        : type === config.TRANSACTIONS.INITIATIVE.TYPE ?
          <img alt="" src={initiative.img}/>
          : 
          <img alt="" src={action_img}/>
        }
        
      </div>}

      {(!product?.img && !initiative?.img && !action_img) 
        && 
        <div className="max-w-[4rem] w-full overflow-hidden flex justify-center items-center">
          <img alt="" src={logo}/>
        </div>}
      <div className='flex-1'>
        <div className="">
          {type === config.TRANSACTIONS.BORROW.TYPE ?  
          sub_type === config.TRANSACTIONS.BORROW.SUB_TYPE.PRODUCT ?
            owner.id === user ?
              <h5 className="">{t(fullname(owner) + " le prestó a " + fullname(borrower) +  " " + product?.name.toLowerCase())} </h5> 
            : 
              <h5 className="">{t(fullname(borrower) + " pidió a " + fullname(owner) +  " " +  product?.name.toLowerCase())}</h5>
              : <h5 className="">{t(fullname(owner) +  ` le brindó el ${getSubTypeString(sub_type)} ` +  offer?.name.toLowerCase() + " a " + fullname(borrower))}</h5>
          : type === config.TRANSACTIONS.INITIATIVE.TYPE ? 
            <h5 className="">{t(fullname(owner) + (status === 'postulated' ? " se postuló a " : " asistió a ") + initiative?.name)} </h5> 
            : 
            <div>
              <h5 className="">{t(fullname(activity.json_data.user) + `${(coins > 0 && points > 0)? " realizó": " intento realizar"}  ` + action.name)} </h5>
              <p className="mt-2 italic">{description} </p> 
            </div> 
          }

        </div>
        <div className="flex justify-between mt-2" >

          {type === config.TRANSACTIONS.BORROW.TYPE ? 
           sub_type === config.TRANSACTIONS.BORROW.SUB_TYPE.PRODUCT ?
            <div className="flex items-center gap-2">
              <IconCoins negative={owner.id !== user} coins={product?.coins}/>
              <IconPoints  points={product?.points}/>
            </div> :
            <div className="flex items-center gap-2">
              <IconCoins negative={owner.id !== user} coins={offer?.coins}/>
              <IconPoints  points={offer?.points}/>
            </div>
          : 
           <div className="flex items-center gap-2">
              {coins > 0 && points > 0 && status !=='archived'&&
                <>
                  <IconCoins key={user* coins} negative={owner.id !== user} coins={coins} />
                  <IconPoints key={user + points} points={points} />
                </>
              }
            </div>
          }

        </div>
        <div className="flex gap-3 justify-between items-center mt-2">
          <h6 className=" text-gray-500">{timeRemains}</h6>
          {!isAfter(dateAndTime,nextDayEnd)&&(coins > 0 && points > 0)&& status !=='archived' &&rest.actions && rest.actions.map((action, index) => 
            <div className="flex justify-end">
              <Button
              key={index} disabled={action.disabled} className={"text-xs btn-link btn-xs p-0 self-center"} 
              title={action.title} onClick={() => isFunction(action.onClick) ? action.onClick() : null }
              />
            </div> 
          )}
          {status == 'archived' && rest.actions &&
            <div className="flex justify-center items-center" >
              <ButtonIcon
                buttonClassName="btn-link text-xs font-bold  capitalize  "
                className="mr-1 w-4 h-4"
                onClick={() =>gotoReclamo()}
                icon="flag"
                text="Rechazar"
              />
            </div>
          }
          {serviceCompleted(status, sub_type) && (
            <small
              className={"badge badge-sm badge-success " + 
              (statuses[status]?.className ? statuses[status]?.className : "text-white ")}
            >
              {"Completo"}
            </small>
          )}
          {statuses && !serviceCompleted(status, sub_type) && (
            <small
              className={`badge badge-sm self-center text-white ${(coins > 0 && points > 0) && status !=='archived' ? "badge-warning ": "badge-error"}`
              // (statuses[status]?.className ? statuses[status]?.className : "text-white ")
            }
            >
              {(coins > 0 && points > 0)? statuses[status]?.nombre : 'Intento'}
              {(status=='archived')?'Invalidado':''}
            </small>
          )
          }
 
        {status === "canceled" ? <small className="mt-2 block text-warning ">Cancelado por: {(canceled_by === "owner") ? fullname(owner) : fullname(borrower)}</small> : null}
      </div> 
        </div>
    </div>
  )
}